// ** Auth Endpoints
export default {
  loginEndpoint: '/auth/user/login',
  forgotPasswordEndpoint: '/auth/user/forgot',
  resetPasswordEndpoint: '/auth/user/reset',
  changePasswordEndpoint: '/user/change-password',
  registerEndpoint: '/jwt/register',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
