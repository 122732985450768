import {
    GET_ALL_CATEGORIES,
    GET_ALL_CATEGORY_URL,
    GET_ALL_item, GET_FLASH_SALE_LABEL,
    GET_GIFT_PRODUCTS,
    GET_MENU_SETTING,
    GET_PAGE_SETTING,
    GET_TRANSPORT_BOX
} from '../action'

const initialState = {
    settingScreen: null,
    wardrobePools: [],
    menuSetting: null,
    urls: [],
    categoryAndPattern: null,
    shoppingCartSetting: {},
    productGifts: [],
    categories: []
}

const pageSetting = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAGE_SETTING:
            return {
                ...state,
                settingScreen: action.settingScreen
            }
        case GET_ALL_item:
            return {
                ...state,
                wardrobePools: action.wardrobePools
            }
        case GET_MENU_SETTING:
            return {
                ...state,
                menuSetting: action.menuSetting
            }
        case GET_ALL_CATEGORY_URL:
            return {
                ...state,
                urls: action.urls
            }
        case GET_ALL_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            }
        case GET_TRANSPORT_BOX:
            return {
                ...state,
                shoppingCartSetting: action.shoppingCartSetting
            }
        case GET_GIFT_PRODUCTS:
            return {
                ...state,
                productGifts: action.data
            }
        case GET_FLASH_SALE_LABEL:
            return {
                ...state,
                flashSales: action.data
            }
        default:
            return { ...state }
    }
}

export default pageSetting
