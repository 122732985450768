import {GET_DASHBOARD_ALL, GET_DASHBOARD_ALL_STATUS} from '../action'

const initialState = {
    dashboards: [],
    dashboards_status: []
}

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_ALL:
            return {
                ...state,
                dashboards: action.dashboards
            }
        case GET_DASHBOARD_ALL_STATUS:
            return {
                ...state,
                dashboards_status: action?.dashboards
            }
        default:
            return { ...state}
    }
}
export default dashboard