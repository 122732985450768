import instances from "../../../../../../@core/plugin/axios"
export const GET_SHOPEE_PRODUCTS = 'GET_SHOPEE_PRODUCTS'
export const GET_SHOPEE_PRODUCT = 'GET_SHOPEE_PRODUCT'

export const getShopeeProductList = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/shopee/compare-product', config).then(response => {
      dispatch({
        type: GET_SHOPEE_PRODUCTS,
        products: response?.data?.data?.compareProductShopees,
        total: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

// ** Get Product
export const getShopeeProduct = id => {
  return async dispatch => {
    await instances
        .get(`/shopee/item/get/${id}`)
        .then(async response => {
          dispatch({type: GET_SHOPEE_PRODUCT,
            product_detail: response?.data?.data?.item,
            type_values: response?.data?.data?.type_values,
            default_type_values: response?.data?.data?.default_type_values,
            variants: response?.data?.data?.item?.variations
          })
        })
        .catch(err => console.log(err))
  }
}

export const syncShopeeProduct = async () => {
  return await instances.get('/sync/products')
}

export const syncShopeeComment = async () => {
  return await instances.get('/sync/comments')
}