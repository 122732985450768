import instances from "../../../../../@core/plugin/axios"
export const GET_PAGING_CATEGORIES = 'GET_PAGING_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'

export const getPagingCategories = (params) => {
  const config = {params: params}
  return async dispatch => {
    await instances.get('/category/limit', config).then(response => {
      dispatch({
        type: GET_PAGING_CATEGORIES,
        categories: response?.data?.data?.categories,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

export const getCategoryById = id => {
  return async dispatch => {
    await instances
        .get(`/category/${id}`)
        .then(response => {
          dispatch({
            type: GET_CATEGORY,
            category: response?.data?.data?.category
          })
        })
        .catch(err => console.log(err))
  }
}

export const uploadImage = async formData => {
  const config = {headers: {'Content-Type': 'multipart/form-data'}}
  return await instances.post('/upload/image', formData, config)
}

export const updateCategoryById = async (category, id) => {
  return await instances.put(`/category/${id}`, category)
}