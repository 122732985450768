import instances from "../../../../../@core/plugin/axios"

export const GET_DASHBOARD_ALL = 'GET_DASHBOARD_ALL'
export const GET_DASHBOARD_ALL_STATUS = 'GET_DASHBOARD_ALL_STATUS'

export const getDashboardStatus = (date, status) => {
   return instances.get(`admin/dash-board/all?${date ? `day=${date}` : ''}${date && status ? '&' : ''}${status ? `status=${status}` : ''}`)
}
export const getCustomerReport = (date) => {
   return instances.get(`admin/customer/report?${date ? `day=${date}` : ''}`)
}
export const getFacebookMarketing = async params => {
   const config = {params: params}
   return await instances.get('/admin/facebook-marketing/report/bm-account', config)
}
export const getFacebookMarketingAccount = async (id, params) => {
   const config = {params: params}
   return await instances.get(`/admin/facebook-marketing/report/account/${id}`, config)
}
export const getFacebookAccount = () => {
   return instances.get(`admin/facebook-account`)
}
export const getAccount = (id) => {
   return instances.get(`/admin/facebook-marketing/report/account/${id}`)
}
export const getCampaignByIdBM = (id) => {
   return instances.get(`admin/facebook-marketing/report/campaign/${id}`)
}
export const getAdSetByIdBM = (id) => {
   return instances.get(`admin/facebook-marketing/report/ad-set/${id}`)
}
export const getAllProducts = async () => {
   return await instances.get('/admin/product/all')
}
export const getOrderPurchaseReport = (params) => {
   const config = {params: params}
   return instances.get(`admin/purchase/report/product`, config)
}
export const getOrderProductVariantReport = (params) => {
   const config = {params: params}
   return instances.get(`admin/purchase/report/variant`, config)
}
export const getOrderPurchaseReportByDate = (params) => {
   const config = {params: params}
   return instances.get(`admin/purchase/report/by-date`, config)
}
export const getOrderPurchaseReportAll = () => {
   return instances.get(`admin/purchase`)
}
export const getOrderReportFacebook = (params) => {
   const config = {params: params}
   return instances.get(`/admin/facebook-elastic`, config)
}
export const getOrderReportFacebookChart = (params) => {
   const config = {params: params}
   return instances.get(`/admin/facebook-elastic/chart`, config)
}
export const getAccounts = async (params) => {
   const config = {params: params}
   return await instances.get(`/admin/facebook-elastic/account`, config)
}
export const getCampaigns = async (params) => {
   const config = {params: params}
   return await instances.get(`/admin/facebook-elastic/campaign`, config)
}
export const getOrderChartType = async () => {
   return await instances.get(`/admin/facebook-elastic/campaign`)
}
export const getProductElastic = async (params) => {
   const config = {params: params}
   return await instances.get(`product/elastic/search`, config)
}
export const getProductVariants = async (params) => {
   const config = {params: params}
   return await instances.get(`admin/product/variant/all`, config)
}