
import {GET_PURCHASE_ORDERS, GET_SUPPLIERS} from '../action'

const initialState = {
    purchase_orders: [],
    suppliers: [],
    total_suppliers: 0
}
const purchase = (state = initialState, action) => {

    switch (action.type) {
        case GET_PURCHASE_ORDERS:
            return {
                ...state,
                products: action.products,
                total: action.totalPages,
                params: action.params
            }
        case GET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.suppliers,
                total_suppliers: action.totalSuppliers,
                params: action.params
            }
        default:
            return { ...state }
    }

}
export default purchase