import {
    GET_SAPO_LOCATION,
    GET_SAPO_PRODUCT,
    GET_SAPO_ORDER,
    GET_SAPO_STAFF,
    GET_SAPO_ACCOUNT,
    GET_SAPO_ACCOUNTS,
    GET_SAPO_SUPPLIER,
    GET_SAPO_PAYMENT_METHODS,
    GET_SAPO_PAYMENT_METHOD,
    GET_SAPO_ORDER_SOURCE_GROUP,
    GET_SAPO_ORDER_SOURCES,
    GET_SAPO_ORDER_SOURCE,
    GET_SAPO_ORDER_SOURCE_GROUPS,
    GET_SAPO_CUSTOMER_GROUPS,
    GET_SAPO_SETTINGS,
    GET_SAPO_TENANTS
} from '../action'

const initialState = {
    locations: [],
    total_location: 0,
    products: [],
    total_product: 0,
    orders: [],
    total_order: 0,
    staffs: [],
    total_staffs: 0,
    accounts: [],
    total_accounts: 0,
    account: null,
    total_order_source_groups: 0,
    order_source_group: {}
}

const settingSapo = (state = initialState, action) => {
    switch (action.type) {
        case GET_SAPO_LOCATION:
            return {
                ...state,
                locations: action?.data?.locations,
                total_location: action?.data?.totalRecords
            }
        case GET_SAPO_SUPPLIER:
            return {
                ...state,
                suppliers: action?.data?.suppliers,
                total_supplier: action?.data?.totalRecords
            }
        case GET_SAPO_PRODUCT:
            return {
                ...state,
                products: action?.data?.products,
                total_product: action?.data?.totalRecords
            }
        case GET_SAPO_ORDER:
            return {
                ...state,
                orders: action?.data?.orders,
                total_order: action?.data?.totalRecords
            }
        case GET_SAPO_STAFF:
            return {
                ...state,
                staffs: action?.data?.staffs,
                total_staffs: action?.data?.totalRecords
            }
        case GET_SAPO_ACCOUNTS:
            return {
                ...state,
                accounts: action?.data?.accounts,
                total_accounts: action?.data?.totalRecords
            }
        case GET_SAPO_ACCOUNT:
            return {
                ...state,
                account: action?.data
            }
        case GET_SAPO_PAYMENT_METHODS:
            return {
                ...state,
                payment_methods: action?.data?.payment_methods,
                total_payment_methods: action?.data?.totalRecords
            }
        case GET_SAPO_PAYMENT_METHOD:
            return {
                ...state,
                payment_method: action?.data
            }
        case GET_SAPO_ORDER_SOURCES:
            return {
                ...state,
                order_sources: action?.data?.order_sources,
                total_order_sources: action?.data?.totalRecords
            }
        case GET_SAPO_ORDER_SOURCE_GROUPS:
            return {
                ...state,
                order_source_groups: action?.data?.order_source_groups,
                total_order_source_groups: action?.data?.totalRecords
            }
        case GET_SAPO_ORDER_SOURCE:
            return {
                ...state,
                order_source: action?.data
            }
        case GET_SAPO_ORDER_SOURCE_GROUP:
            return {
                ...state,
                order_source_group: action?.data
            }
        case GET_SAPO_CUSTOMER_GROUPS:
            return {
                ...state,
                customer_groups: action?.data?.customer_groups,
                total_customer_groups: action?.data?.totalRecords
            }
        case GET_SAPO_SETTINGS:
            return {
                ...state,
                settings: action?.data?.settings,
                total_settings: action?.data?.totalRecords
            }
        case GET_SAPO_TENANTS:
            return {
                ...state,
                tenants: action?.data?.tenants,
                total_tenants: action?.data?.totalRecords
            }
        default:
            return { ...state }
    }
}
export default settingSapo