// ** Initial State
import {
  GET_SOCIAL_STORIES,
  GET_SOCIAL_STORY,
  ADD_SOCIAL_STORY,
  DELETE_SOCIAL_STORY, EDIT_SOCIAL_STORY
} from "../action"

const initialState = {
  socialStories: [],
  socialStory: {},
  newSocialStory: {},
  total: 0,
  params: {}
}

const instagram = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOCIAL_STORIES:
      return {
        ...state,
        socialStories: action.socialStories,
        total: action.totalPages,
        params: action.params
      }
    case GET_SOCIAL_STORY:
      return {
        ...state,
        socialStory: action.socialStory
      }
    case ADD_SOCIAL_STORY:
      return { ...state }
    case EDIT_SOCIAL_STORY:
      return { ...state }
    case DELETE_SOCIAL_STORY:
      return { ...state }
    default:
      return { ...state }
  }
}
export default instagram
