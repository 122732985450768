import instances from "../../../../../@core/plugin/axios"
export const GET_JOBS = 'GET_JOBS'
export const GET_JOB = 'GET_JOB'
export const GET_JOB_FORMALITIES = 'GET_JOB_FORMALITIES'
export const DELETE_JOB = 'DELETE_JOB'

// ** Get Jobs
export const getJobs = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/recruitment/job/all', config).then(response => {
            dispatch({
                type: GET_JOBS,
                jobs: response?.data?.data?.jobs,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

// ** Get job
export const getJob = id => {
    return async dispatch => {
        await instances
            .get(`/admin/recruitment/job/${id}`)
            .then(async response => {
                dispatch({
                    type: GET_JOB,
                    job: response?.data?.data?.job
                })
            })
            .catch(err => console.log(err))
    }
}

// ** Get Job Formalities
export const getJobFormalities = () => {
    return async dispatch => {
        await instances.get('/admin/recruitment/formality/all').then(response => {
            dispatch({
                type: GET_JOB_FORMALITIES,
                formalities: response?.data?.data
            })
        })
    }
}

// ** Create job
export const createJob = async (data) => {
    return instances.post('/admin/recruitment/job/create', data)
}

// ** Create job formality
export const createJobFormality = async (data) => {
    return instances.post('/admin/recruitment/formality/create', data)
}

// ** Edit job
export const updateJob = async (id, data) => {
    return instances.put(`/admin/recruitment/job/update/${id}`, data)
}

// ** Delete job
export const deleteJob = id => {
    return (dispatch, getState) => {
        instances.delete(`/admin/recruitment/job/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_JOB
                })
            })
            .then(() => {
                dispatch(getJobs(getState()?.recruitment?.params))
            })
    }
}

export const uploadImage = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    return await instances.post('/upload/image', formData, config)
}
