// ** Initial State
import {GET_SHOPEE_PRODUCT, GET_SHOPEE_PRODUCTS} from "../action"

const initialState = {
  products: [],
  total: 0,
  params: {}
}

const shopee = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOPEE_PRODUCTS:
      return {...state, products: action.products, total: action.total}
    case GET_SHOPEE_PRODUCT:
      return { ...state,
        product_detail: action.product_detail,
        type_values: action.type_values,
        default_type_values: action.default_type_values,
        variants: action.variants
      }
    default:
      return { ...state }
  }
}
export default shopee
