import instances from "../../../../../@core/plugin/axios"
export const GET_WARDROBES = 'GET_WARDROBES'
export const GET_WARDROBE = 'GET_WARDROBE'
export const DELETE_WARDROBE = 'DELETE_WARDROBE'
export const GET_SCREENS = 'GET_SCREENS'


// ** Get data on page or row change
export const getWardrobes = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/wardrobe/all', config).then(response => {
      dispatch({
        type: GET_WARDROBES,
        wardrobes: response?.data?.data?.wardrobes,
        totalPages: response?.data?.data?.totalRecords,
        params: params
      })
    })
  }
}


export const getScreens = () => {
    return async dispatch => {
        await instances.get('/admin/screen').then(response => {
            dispatch({
                type: GET_SCREENS,
                screens: response?.data?.data
            })
        })
    }
}

// ** Get Product
export const getWardrobe = id => {
  return async dispatch => {
    await instances
      .get(`/admin/wardrobe/${id}`)
      .then(response => {
        dispatch({
          type: GET_WARDROBE,
          wardrobe: response?.data?.data?.wardrobe
        })
      })
      .catch(err => console.log(err))
  }
}

export const getWardrobeV2 = async id => {
    return await instances.get(`/admin/wardrobe/${id}`)
}


export const deleteWardrobe = id => {
  return (dispatch, getState) => {
    instances.delete(`/admin/wardrobe/${id}`)
        .then(() => {
          dispatch({
            type: DELETE_WARDROBE
          })
        })
        .then(() => {
          dispatch(getWardrobes(getState().wardrobes.params))
        })
  }
}

export const createWardrobe = async (data) => {
    return await instances.post(`/admin/wardrobe`, data)
}

export const updateWardrobe = async (data, id) => {
    return await instances.put(`/admin/wardrobe/${id}`, data)
}
