import instances from "../../../../../@core/plugin/axios"

export const INITIAL_COLLECTION = 'INITIAL_COLLECTION'
export const GET_COLLECTION = 'GET_COLLECTION'
export const GET_ALL_COLLECTION = 'GET_ALL_COLLECTION'

export const initCollection = () => {
    return async dispatch => {
        await instances.put('admin/collection/initial').then(response => {
            dispatch({
                type: INITIAL_COLLECTION,
                initial_collection: response?.data?.data
            })
        })
    }
}

export const getCollection = (id) => {
    return async dispatch => {
        await instances.get(`admin/collection/detail/${id}`).then(response => {
            dispatch({
                type: GET_COLLECTION,
                collection: response?.data?.data
            })
        })
    }
}

export const createCollection = async (data) => {
    return await instances.put('/admin/collection/create', data)
}

export const updateCollection = async (data) => {
    return await instances.put('/admin/collection/update', data)
}

export const publishCollection = async (data) => {
    return await instances.post('/admin/collection/publish', data)
}

export const getCollections = (params) => {
    const config = {params: params}

    return async dispatch => {
        await instances.get('/admin/collection/all', config).then(response => {
            dispatch({
                type: GET_ALL_COLLECTION,
                collections: response?.data?.data?.collections,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const uploadVideo = formData => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    return instances.post('/upload/video', formData, config)
}

export const uploadMultiImages = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    return await instances.post('/upload/images', formData, config)
}

export const uploadImageWithName = async (path, name, formData) => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}, params: {path: path, name: name}}
    return await instances.post('/upload/image_with_name', formData, config)
}