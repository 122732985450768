import {
    DELETE_PROMOTION,
    GET_PROMOTION,
    GET_PROMOTIONS_BY_GIFT,
    GET_PROMOTIONS_BY_TYPE
} from "../action"

const initialState = {
    promotions: [],
    promotion: null,
    total: 0,
    params: {},
    products: []
}

const promotion = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROMOTIONS_BY_TYPE:
            return {
                ...state,
                promotions: action?.promotions,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_PROMOTIONS_BY_GIFT:
            return {
                ...state,
                promotions: action?.promotions
            }
        case GET_PROMOTION:
            return {
                ...state,
                promotion: action?.promotion
            }
        case DELETE_PROMOTION:
            return {...state}
        default:
            return {...state}
    }
}

export default promotion
