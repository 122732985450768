import axios from 'axios'
import instances from '../../../../../@core/plugin/axios'
export const GET_ROLES = 'GET_ROLES'
export const GET_ROLE = 'GET_ROLE'
export const DISABLE_ROLE = 'DISABLE_ROLE'
export const GET_PERMISSION_SCREENS = 'GET_PERMISSION_SCREENS'
export const GET_PERMISSION_GROUPS = 'GET_PERMISSION_GROUPS'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'
// ** Get all Data
export const getAllData = () => {
  return async dispatch => {
    await axios.get('/api/users/list/all-data').then(response => {
      dispatch({
        type: 'GET_ALL_DATA',
        data: response.data
      })
    })
  }
}

// ** Get data on page or row change
export const getData = params => {
  return async dispatch => {
    await axios.get('/api/users/list/data', params).then(response => {
      dispatch({
        type: 'GET_DATA',
        data: response.data.users,
        totalPages: response.data.total,
        params
      })
    })
  }
}

export const getRoles = () => {
  return async dispatch => {
    await instances.get('/user/roles').then(response => {
      dispatch({
        type: GET_ROLES,
        data: response?.data?.data
      })
    })
  }
}

export const getRole = id => {
  return async dispatch => {
    await instances
        .get(`/user/role/${id}`)
        .then(response => {
          dispatch({
            type: GET_ROLE,
            role: response?.data?.data
          })
        })
        .catch(err => console.log(err))
  }
}

export const createRole = async (data) => {
  return await instances.post(`/user/role`, data)
}

export const updateRole = async (data, id) => {
  return await instances.put(`/user/role/${id}`, data)
}

export const disabledPermission = id => {
  return async dispatch => {
    await instances
        .get(`/user/role/${id}`)
        .then(response => {
          dispatch({
            type: DISABLE_ROLE,
            role: response?.data?.data
          })
        })
        .then(() => dispatch(getRoles()))
  }
}

export const getPermissionScreens = () => {
  return async dispatch => {
    await instances.get('/admin/user/permission/screen/all').then(response => {
      dispatch({
        type: GET_PERMISSION_SCREENS,
        screens: response?.data?.data
      })
    })
  }
}

export const getPermissionGroups = () => {
  return async dispatch => {
    await instances.get('/admin/user/permission/group/all').then(response => {
      dispatch({
        type: GET_PERMISSION_GROUPS,
        permission_groups: response?.data?.data
      })
    })
  }
}

export const getPermissions = () => {
  return async dispatch => {
    await instances.get('/user/permissions').then(response => {
      dispatch({
        type: GET_PERMISSIONS,
        permissions: response?.data?.data
      })
    })
  }
}

