// ** Initial State
import {GET_JOBS, GET_JOB, GET_JOB_FORMALITIES, DELETE_JOB} from "../action"

const initialState = {
    jobs: [],
    job: {},
    params: {},
    total: 0,
    formalities: []
}

const recruitment = (state = initialState, action) => {
    switch (action.type) {
        case GET_JOB_FORMALITIES:
            return {
                ...state,
                formalities: action.formalities
            }
        case GET_JOBS:
            return {
                ...state,
                jobs: action.jobs,
                total: action.totalPages,
                params: action.params
            }
        case DELETE_JOB:
            return { ...state }
        case GET_JOB:
            return {
                ...state,
                job: action.job
            }
        default:
            return { ...state }
    }
}
export default recruitment
