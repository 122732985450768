import instances from "../../../../../@core/plugin/axios"

export const GET_PRODUCT_EVENT = 'GET_PRODUCT_EVENT'
export const GET_PRODUCT_EVENT_BY_ID = 'GET_PRODUCT_EVENT_BY_ID'
export const DELETE_PRODUCT_EVENT_BY_ID = 'DELETE_PRODUCT_EVENT_BY_ID'

export const getProductEvent = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('thumb', config).then(res => {
            dispatch({
                type: GET_PRODUCT_EVENT,
                product_events: res?.data?.data?.product_thumbs,
                totalPages: res?.data?.data?.totalRecords,
                params
            })
        })
    }
}
export const createProductEvent = async data => {
    return await instances.post('admin/thumb', data)
}
export const updateProductEvent = async (id, data) => {
    return await instances.put(`admin/thumb/${id}`, data)
}

export const getProductEventById = async id => {
    return await instances.get(`admin/thumb/${id}`)
}

export const deleteProductEvent = id => {
    return async (dispatch, getState) => {
        await instances.delete(`admin/thumb/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_PRODUCT_EVENT_BY_ID
                })
            })
            .then(() => dispatch(
                getProductEvent(getState().productEvent.params))
            )
    }
}
