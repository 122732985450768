import instances from "../../../../../@core/plugin/axios"
export const GET_ALL_GALLERY = 'GET_ALL_GALLERY'
export const GET_GALLERIES = 'GET_GALLERIES'
export const DELETE_GALLERY = 'DELETE_GALLERY'
export const GET_GALLERY = 'GET_GALLERY'
export const UPDATE_STATUS_GALLERY = 'UPDATE_STATUS_GALLERY'

// ** Get data on page or row change
export const getAllGallery = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/gallery/paging', config).then(response => {
            dispatch({
                type: GET_ALL_GALLERY,
                galleries: response?.data?.data?.galleries,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getGalleries = () => {
    return async dispatch => {
        await instances.get('/admin/gallery/all').then(response => {
            dispatch({
                type: GET_GALLERIES,
                galleries: response?.data?.data?.galleries
            })
        })
    }
}

export const getGallery = id => {
    return async dispatch => {
        await instances
            .get(`/gallery/${id}`)
            .then(response => {
                dispatch({
                    type: GET_GALLERY,
                    gallery: response.data.gallery
                })
            })
            .catch(err => console.log(err))
    }
}

export const deleteGallery = id => {
    return (dispatch, getState) => {
        instances.delete('/gallery/', {id})
            .then(() => {
                dispatch({
                    type: DELETE_GALLERY
                })
            })
            .then(() => {
                dispatch(getAllGallery(getState().products.params))
            })
    }
}

export const updateStatusGallery = async gallery => {
    const request = {}
    request.name = gallery.name
    request.email = gallery.email
    request.message = gallery.message
    request.is_active = gallery.is_active
    if (gallery.product_id) {
        request.product_id = gallery.product_id
    }
    return await instances.put(`/admin/gallery/${gallery.id}`, request)
}
