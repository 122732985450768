// ** Initial State

import {GET_ROLES, GET_ROLE, GET_PERMISSION_SCREENS, GET_PERMISSIONS, GET_PERMISSION_GROUPS} from '../action'

const initialState = {
  allData: [],
  data: [],
  roles: [],
  role: {},
  screens: [],
  total: 1,
  params: {},
  permission_groups: []
}

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {...state, roles: action.data}
    case GET_ROLE:
      return  {...state, role: action.role}
    case GET_PERMISSIONS:
      return  {...state, permissions: action.permissions}
    case GET_PERMISSION_SCREENS:
      return  {...state, screens: action.screens}
    case GET_PERMISSION_GROUPS:
      return  {...state, permission_groups: action.permission_groups}
    default:
      return { ...state }
  }
}
export default permissions
