import {
    GET_ORDER,
    GET_ORDER_RETURNS,
    GET_ORDER_TRACKING_BY_ID,
    GET_ORDERS,
    GET_ORDER_RETURN, GET_USER_ASSIGNEE, GET_ORDER_WEBS
} from "../action"

const initialState = {
    orders: [],
    order_webs: [],
    order_returns: [],
    order: null,
    total: 0,
    params: {},
    orderTracking: [],
    order_return: null,
    user: null
}

const orders = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.orders,
                total: action.totalPages,
                params: action.params
            }
        case GET_ORDER_WEBS:
            return {
                ...state,
                order_webs: action.order_webs,
                total_webs: action.totalPages,
                params: action.params
            }
        case GET_ORDER_RETURNS:
            return {
                ...state,
                order_returns: action.order_returns,
                total: action.totalPages,
                params: action.params
            }
        case GET_ORDER:
            return {
                ...state,
                order: action.order
            }
        case GET_ORDER_RETURN:
            return {
                ...state,
                order_return: action.order_return
            }
        case GET_ORDER_TRACKING_BY_ID:
            return {
                ...state,
                orderTracking: action.orderTracking
            }
        case GET_USER_ASSIGNEE:
            return {
                ...state,
                user: action.user
            }
        default:
            return { ...state }
    }
}

export default orders
