import instances from "../../plugin/axios"
import jwtDefaultConfig from "./jwtDefaultConfig"

export default class JwtService {
  jwtConfig = {...jwtDefaultConfig}
  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
  }

  login(...args) {
    return instances.post(instances.defaults.baseURL + this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return instances.post(this.jwtConfig.registerEndpoint, ...args)
  }

  forgotPassword(email) {
    const body = {email: email}
    return instances.post(instances.defaults.baseURL + this.jwtConfig.forgotPasswordEndpoint, body)
  }

  setPassword(sessionId, newPassword) {
    const body = {session_id: sessionId, password: newPassword}
    return instances.put(instances.defaults.baseURL + this.jwtConfig.resetPasswordEndpoint, body)
  }

  changePassword(userId, oldPassword, newPassword) {
    const body = {userId: userId, oldPassword: oldPassword, newPassword: newPassword}
    return instances.put(instances.defaults.baseURL + this.jwtConfig.changePasswordEndpoint, body)
  }
}
