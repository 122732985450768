import instances from "../../../../../@core/plugin/axios"
import {getUserData} from "../../../../../auth/utils"

export const GET_CUSTOMER_DETAIL = 'GET_CUSTOMER_DETAIL'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_COINS = 'GET_COINS'
export const GET_CUSTOMERS = 'GET_FEAER_CUSTOMERS'
export const GET_CUSTOMER = 'GET_FEAER_CUSTOMER'
export const EDIT_CUSTOMER = 'EDIT_FEAER_CUSTOMER'
export const BLOCK_CUSTOMER = 'BLOCK_FEAER_CUSTOMER'

// ** Get data on page or row change
export const getCustomers = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/customer/all', config).then(response => {
      dispatch({
        type: GET_CUSTOMERS,
        customers: response?.data?.data?.customers,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

// ** Get User
export const getCustomer = id => {
  return async dispatch => {
    await instances
      .get('/admin/customer/', { id })
      .then(response => {
        dispatch({
          type: GET_CUSTOMER,
          customer: response?.data?.data?.customer
        })
      })
      .catch(err => console.log(err))
  }
}

export const getCustomerDetail = id => {
  return async dispatch => {
    await instances
        .get(`/customer/${id}`)
        .then(response => {
          dispatch({
            type: GET_CUSTOMER_DETAIL,
            customerDetail: response?.data?.data
          })
        })
        .catch(err => console.log(err))
  }
}
// ** edit customer
export const editCustomer = customer => {
  return (dispatch, getState) => {
    instances.put('/admin/customer', JSON.stringify(customer))
      .then(response => {
        dispatch({
          type: EDIT_CUSTOMER,
          customer
        })
      })
      .then(() => {
        dispatch(getCustomer(getState().customers.params))
      })
      .catch(err => console.log(err))
  }
}

// ** edit customer
export const blockCustomer = id => {
  return (dispatch, getState) => {
    instances.delete('/admin/customer', { id })
      .then(() => {
        dispatch({
          type: BLOCK_CUSTOMER,
          blockStatus: true
        })
      })
      .then(() => {
        dispatch(getCustomer(getState().customers.params))
      })
  }
}

export const getOrders = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/order/customer/all', config).then(response => {
      dispatch({
        type: GET_ORDERS,
        orders: response?.data?.data?.orders,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}
export const getCoins = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/coin-history/by-customer', config).then(response => {
      dispatch({
        type: GET_COINS,
        coinHistories: response?.data?.data?.coinHistories,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

export const checkPermissionByScreen = async (permissionScreen) => {
  const userRole = getUserData()?.role
  let permissionCheck = {view: false, create: false, delete: false, edit: false, import: false, export: false}
  if (userRole?.name === 'ADMIN') {
    permissionCheck = {view: true, create: true, delete: true, edit: true, import: true, export: true}
  } else {
    if (userRole?.id) {
      await instances.get(`/user/role/${userRole?.id}`).then(res => {
        if (res?.data?.data) {
          const permissions = res?.data?.data?.permissions
          if (permissions?.length > 0) {
            permissions?.forEach(permission => {
              if (permission?.name?.includes(permissionScreen)) {
                switch (permission?.name?.split('_')?.[0]) {
                  case 'view':
                    permissionCheck.view = true
                    break
                  case 'create':
                    permissionCheck.create = true
                    break
                  case 'edit':
                    permissionCheck.edit = true
                    break
                  case 'delete':
                    permissionCheck.delete = true
                    break
                  case 'import':
                    permissionCheck.import = true
                    break
                  case 'export':
                    permissionCheck.export = true
                    break
                  default:
                    break
                }
              }
            })
          }
        }
      })
    }
  }
  return permissionCheck
}