import instances from "../../../../../@core/plugin/axios"

export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'
export const GET_PROVINCES = 'GET_PROVINCES'
export const GET_DISTRICTS = 'GET_DISTRICTS'
export const GET_WARDS = 'GET_WARDS'
export const GET_ERROR = 'GET_ERROR'

export const getUsers = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/user/all', config).then(response => {
      dispatch({
        type: GET_USERS,
        users: response?.data?.data?.users,
        totalPages: response?.data?.data?.totalRecords,
        params: params
      })
    })
  }
}

export const getProvinces = () => {
  return async dispatch => {
    await instances.get('/address/provinces').then(response => {
      dispatch({
        type: GET_PROVINCES,
        provinces: response?.data?.data?.provinces
      })
    })
  }
}

export const getDistricts = (provinceId) => {
  return async dispatch => {
    await instances.get(`/address/districts/${provinceId}`).then(response => {
      dispatch({
        type: GET_DISTRICTS,
        districts: response?.data?.data?.districts
      })
    })
  }
}

export const getWards = (wardId) => {
  return async dispatch => {
    await instances.get(`/address/wards/${wardId}`).then(response => {
      dispatch({
        type: GET_WARDS,
        wards: response?.data?.data?.wards
      })
    })
  }
}

export const getUserV2 = id => {
  return  instances.get(`/admin/user/${id}`)
}

// ** Get User
export const getUser = id => {
  return async dispatch => {
    await instances
      .get(`/admin/user/${id}`)
      .then(response => {
        dispatch({
          type: GET_USER,
          selectedUser: response.data.data
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Add new user
export const addUser = user => {
  return (dispatch, getState) => {
    instances
      .post('/admin/user', user)
      .then(response => {
        dispatch({
          type: 'ADD_USER',
          user
        })
      })
      .then(() => {
        dispatch(getUsers(getState().users.params))
      })
      .catch(err => dispatch(
          {
            type: 'GET_ERROR',
            errorMessage: err?.response?.data?.message
          })
      )
  }
}

export const updateUser = (user, id) => {
  return (dispatch, getState) => {
    instances
      .put(`/user/update/${id}`, user)
      .then(res => {
        dispatch({
          type: 'EDIT_USER',
          user
        })
      }).then(() => {
          dispatch(getUsers(getState().users.params))
        })
        .catch(err => dispatch(
            {
              type: 'GET_ERROR',
              errorMessage: err?.response?.data?.message
            })
        )
  }
}