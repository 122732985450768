// ** Initial State

import {DELETE_WARDROBE, GET_SCREENS, GET_WARDROBE, GET_WARDROBES} from "../action"

const initialState = {
  wardrobes: [],
  screens: [],
  total: 0,
  params: {},
  wardrobe: {},
  newWardrobe: {detail_groups: [{}]}
}

const wardrobes = (state = initialState, action) => {
  switch (action.type) {
    case GET_WARDROBES:
      return {
        ...state,
        wardrobes: action.wardrobes,
        total: action.totalPages,
        params: action.params
      }
    case GET_SCREENS:
      return {
        screens: action.screens
      }
    case GET_WARDROBE:
      return { ...state, wardrobe: action.wardrobe }
    case DELETE_WARDROBE:
      return { ...state }
    default:
      return { ...state }
  }
}
export default wardrobes
