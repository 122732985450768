// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import customers from "../../pages/users/customer/store/reducer"
import products from "../../pages/products/product/store/reducer"
import wardrobes from "../../pages/operation/wardrobe/store/reducer"
import galleries from "../../pages/operation/gallery/store/reducer"
import instagram from "../../pages/operation/instagram/store/reducer"
import orders from "../../pages/order/store/reducer"
import promotion from '../../pages/promotions/store/reducer'
import pageSetting from '../../pages/setting/website/shopping/store/reducer'
import permissions from "../../pages/users/permission/store/reducer"
import ratings from "../../pages/operation/ratings/store/reducer"
import users from "../../pages/users/user/store/reducer"
import settingSapo from '../../pages/setting/sapo/store/reducer'
import collection from "../../pages/operation/collection/store/reducer"
import mixProduct from "../../pages/products/collection/store/reducer"
import notification from '../../pages/setting/notification/store/reducer'
import recruitment from "../../pages/setting/recruitment/store/reducer"
import categories from "../../pages/products/category/store/reducer"
import shopee from "../../pages/products/ecommerce/shopee/store/reducer"
import dashboard from "../../pages/report/dashboard/store/reducer"
import productEvent from "../../pages/products/event/store/reducer"
import popup from "../../components/PopUp/store/reducer"
import siteMaps from "../../pages/setting/site-map/store/reducer"
import purchase from "../../pages/warehouse/purchase-order/store/reducer"
import profiles from "../../pages/users/profile/store/reducer"
import contacts from "../../pages/users/customer/contact/store/reducer"
import comments from "../../pages/users/customer/comment/store/reducer"
import landings from "../../pages/setting/website/list/landing/store/reducer"
import websites from "../../pages/setting/website/list/shopping/store/reducer"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  customers,
  products,
  wardrobes,
  galleries,
  instagram,
  orders,
  promotion,
  popup,
  pageSetting,
  permissions,
  ratings,
  users,
  settingSapo,
  collection,
  mixProduct,
  notification,
  recruitment,
  categories,
  shopee,
  dashboard,
  productEvent,
  siteMaps,
  purchase,
  profiles,
  contacts,
  comments,
  landings,
  websites
})

export default rootReducer
