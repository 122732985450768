import {GET_PRODUCT_EVENT, GET_PRODUCT_EVENT_BY_ID} from '../action'

const initialState = {
    product_events: [],
    total: 0,
    param: {},
    product_event: {}
}

const productEvent = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_EVENT:
            return {
                ...state,
                product_events: action?.product_events,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_PRODUCT_EVENT_BY_ID:
            return {
                ...state,
                product_event: action?.product_event
            }
        default:
            return {...state}
    }
}

export default productEvent