import instances from "../../../../../../@core/plugin/axios"
import {PAGE_ENUM} from "../../../../../../enum/page-constant"
export const GET_PAGE_SETTING = 'GET_PAGE_SETTING'
export const GET_ALL_item = 'GET_ALL_item'
export const GET_ALL_CATEGORY_URL = 'GET_ALL_CATEGORY_URL'
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_MENU_SETTING = 'GET_MENU_SETTING'
export const GET_TRANSPORT_BOX = 'GET_TRANSPORT_BOX'
export const GET_GIFT_PRODUCTS = 'GET_GIFT_PRODUCTS'
export const GET_FLASH_SALE_LABEL = 'GET_FLASH_SALE_LABEL'

export const getSettingByScreen = (screen, website) => {
    return async dispatch => {
        await instances.get(`/admin/setting-screen/screen?screen=${screen}&website=${website}`).then(response => {
            dispatch({
                type: GET_PAGE_SETTING,
                screen: screen,
                settingScreen: response?.data?.data
            })
        })
    }
}

export const getSettingByScreenV2 = (screen, website, groupId) => {
    return instances.get(`/admin/screen?screen=${screen}&website=${website}&group_id=${groupId}`)
}

export const getSettingScreens = () => {
    return async dispatch => {
        await instances.get('/shopping/all').then(response => {
            dispatch({
                type: GET_ALL_item,
                wardrobePools: response?.data?.data
            })
        })
    }
}

export const getMenuByEnum = (menu, website, groupId) => {
    return async dispatch => {
        await instances.get(`/admin/menu/enum?menu=${menu}&website=${website}&group_id=${groupId}`).then(response => {
            dispatch({
                type: GET_MENU_SETTING,
                menuSetting: response?.data?.data
            })
        })
    }
}

export const getMenuByEnumV2 = async (menu, website, groupId) => {
    return await instances.get(`/admin/menu/enum?menu=${menu}&website=${website}&group_id=${groupId}`)
}

export const getCategoryUrls = () => {
    return async dispatch => {
        await instances.get('/admin/category-url/all').then(response => {
            dispatch({
                type: GET_ALL_CATEGORY_URL,
                urls: response?.data?.data
            })
        })
    }
}

export const getCategoryUrlsByType = (type) => {
    return async dispatch => {
        await instances.get(`/admin/category-url/${type}`).then(response => {
            dispatch({
                type: GET_ALL_CATEGORY_URL,
                urls: response?.data?.data
            })
        })
    }
}

export const getCategories = () => {
    return async dispatch => {
        await instances.get('/category/all').then(response => {
            dispatch({
                type: GET_ALL_CATEGORIES,
                categories: response?.data?.data
            })
        })
    }
}

export const getCategoryAndPatternV2 = async (group) => {
    return await instances.get(`/category/url/${group}`)
}

export const getCategoryByPromotionV2 = async () => {
    return await instances.get(`/admin/category-url/promotion`)
}

export const uploadVideo = formData => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    return instances.post('/upload/video', formData, config)
}

export const uploadImage = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    if (process.env.REACT_APP_UPLOAD_LOCAL === 'true') {
        return await instances.post('/upload/local/image', formData, config)
    } else {
        return await instances.post('/upload/image', formData, config)
    }
}

export const uploadMultiImages = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    if (process.env.REACT_APP_UPLOAD_LOCAL === 'true') {
        return await instances.post('/upload/local/images', formData, config)
    } else {
        return await instances.post('/upload/images', formData, config)
    }
}

export const uploadImageWithName = async (path, name, formData) => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}, params: {path: path, name: name}}
    if (process.env.REACT_APP_UPLOAD_LOCAL === 'true') {
        return await instances.post('/upload/local/image', formData, config)
    } else {
        return await instances.post('/upload/image', formData, config)
    }
}

export const publishSettingWebsite = async (groupId) => {
    return await instances.post(`/admin/setting/website/publish/${groupId}`)
}

export const updateSettingScreen = async (data) => {
    return await instances.put(`/admin/screen/update`, data)
}

export const updateMenu = async (id, data) => {
    return await instances.put(`/admin/menu/${id}`, data)
}

export const getGiftProducts = () => {
    return async dispatch => {
        await instances.get('/admin/product/gift').then(response => {
            dispatch({
                type: GET_GIFT_PRODUCTS,
                data: response?.data?.data?.products
            })
        })
    }
}

export const getTransportBox = () => {
    return async dispatch => {
        await instances.get('/admin/transport-box').then(response => {
            dispatch({
                type: GET_TRANSPORT_BOX,
                shoppingCartSetting: response?.data?.data || {}
            })
        })
    }
}

export const createTransportBox = async data => {
    return await instances.post('admin/transport-box/create', data)
}

export const getFlashSaleLabel = () => {
    return async dispatch => {
        await instances.get('/promotion/flash-sale/detail/active').then(response => {
            dispatch({
                type: GET_FLASH_SALE_LABEL,
                data: response?.data?.data
            })
        })
    }
}