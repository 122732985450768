import {GET_PROFILE} from '../action'

// ** Initial State
const initialState = {
  user: null
}

const profiles = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        user: action.data.data
      }
    default:
      return { ...state }
  }
}
export default profiles
