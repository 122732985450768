// ** Initial State
import {
  GET_CONTACTS
} from "../action"

const initialState = {
  contacts: []
}

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}
export default contacts
