import instances from "../../../../../@core/plugin/axios"
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const GET_ALL_CATEGORY_URL = 'GET_ALL_CATEGORY_URL'

// ** Get Notifications
export const getNotifications = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/notification/all', config).then(response => {
            dispatch({
                type: GET_NOTIFICATIONS,
                notifications: response?.data?.data?.notifications,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

// ** Get Notification
export const getNotification = id => {
    return async dispatch => {
        await instances
            .get(`/admin/notification/${id}`)
            .then(async response => {
                dispatch({
                    type: GET_NOTIFICATION,
                    notification: response?.data?.data?.notification
                })
            })
            .catch(err => console.log(err))
    }
}

export const getCategoryUrls = () => {
    return async dispatch => {
        await instances.get('/admin/category-url/all').then(response => {
            dispatch({
                type: GET_ALL_CATEGORY_URL,
                urls: response?.data?.data
            })
        })
    }
}

// ** Create notification
export const createNotification = async (data) => {
    return instances.post('/admin/notification/create', data)
}

// ** Edit notification
export const updateNotification = async (id, data) => {
    return instances.put(`/admin/notification/update/${id}`, data)
}

// ** Delete notification
export const deleteNotification = id => {
    return (dispatch, getState) => {
        instances.delete(`/admin/notification/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_NOTIFICATION
                })
            })
            .then(() => {
                dispatch(getNotifications(getState()?.notification?.params))
            })
    }
}

export const uploadImage = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    return await instances.post('/upload/image', formData, config)
}
