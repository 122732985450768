import instances from "../../../../../@core/plugin/axios"
export const GET_PROFILE = 'GET_PROFILE'
// ** Get all Data
export const getMyProfile = () => {
  return async dispatch => {
    await instances.get('/user/profile').then(response => {
      dispatch({
        type: GET_PROFILE,
        data: response.data
      })
    })
  }
}
