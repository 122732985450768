import instances from "../../../../../../../@core/plugin/axios"

export const GET_LANDING_GROUPS = 'GET_LANDING_GROUPS'
export const UPDATE_LANDING = 'UPDATE_LANDING'
export const DELETE_LANDING = 'DELETE_LANDING'

export const getAllLandings = (website) => {
    return instances.get(`/admin/setting-landing/all?website=${website}`)
}

export const getLandings = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/setting-landing/group/paging', config).then(response => {
      dispatch({
        type: GET_LANDING_GROUPS,
        landings: response?.data?.data?.landings,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

export const createSettingLandingGroup = (request) => {
  return instances.post(`/admin/setting-landing/group`, request)
}

export const updateSettingLandingGroup = (id) => {
  return (dispatch, getState) => {
    instances.put(`/admin/setting-landing/group/${id}`)
        .then(() => {
          dispatch({
            type: UPDATE_LANDING
          })
        })
        .then(() => {
          dispatch(getLandings(getState().landings.params))
        })
  }
}


export const deleteSettingLandingGroup = (id) => {
  return (dispatch, getState) => {
    instances.delete(`/admin/setting-landing/group/${id}`)
        .then(() => {
          dispatch({
            type: DELETE_LANDING
          })
        })
        .then(() => {
          dispatch(getLandings(getState().landings.params))
        })
  }
}

