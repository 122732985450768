import {
    GET_COMBO_PRODUCTS,
    GET_DEAL_SHOCK_PRODUCTS, GET_DISCOUNT_PRODUCTS,
    GET_FLASH_SALE_PRODUCTS,
    GET_GIFT_PRODUCTS, GET_SALE_OFF_PRODUCTS,
    GET_SELL_PRODUCTS
} from "../action"
import {GET_CATEGORIES, GET_PRODUCTS} from "../../../../pages/products/product/store/action"

const initialState = {
    sellProducts: [],
    giftProducts: [],
    products: []
}

const popup = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            const categoryConverts = []
            categoryConverts.push({value: '', label: 'Nhóm sản phẩm'})
            action?.categories?.forEach(category => {
                categoryConverts.push({value: category.id, label: category.category})
            })
            return {...state, categories: categoryConverts}
        case GET_PRODUCTS:
            return {
                ...state,
                products: action?.products,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_SELL_PRODUCTS:
            return {
                ...state,
                sellProducts: action?.sellProducts,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_GIFT_PRODUCTS:
            return {
                ...state,
                giftProducts: action?.giftProducts,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_FLASH_SALE_PRODUCTS:
            return {
                ...state,
                products: action?.products,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_COMBO_PRODUCTS:
            return {
                ...state,
                products: action?.products,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_DISCOUNT_PRODUCTS:
            return {
                ...state,
                products: action?.products,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_DEAL_SHOCK_PRODUCTS:
            return {
                ...state,
                products: action?.products,
                total: action?.totalPages,
                params: action?.params
            }
        case GET_SALE_OFF_PRODUCTS:
            return {
                ...state,
                products: action?.products,
                total: action?.totalPages,
                params: action?.params
            }
        default:
            return {...state}
    }
}

export default popup
