import instances from "../../../../../../../@core/plugin/axios"

export const GET_WEBSITES = 'GET_WEBSITES'
export const UPDATE_WEBSITE = 'UPDATE_WEBSITE'
export const DELETE_WEBSITE = 'DELETE_WEBSITE'

export const getAllWebsites = (website) => {
    return instances.get(`/admin/setting-website/all?website=${website}`)
}

export const getWebsites = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/setting-website/paging', config).then(response => {
      dispatch({
        type: GET_WEBSITES,
        websites: response?.data?.data?.websites,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

export const createSettingWebsite = (request) => {
  return instances.post(`/admin/setting-website`, request)
}

export const updateSettingWebsite = (id) => {
  return (dispatch, getState) => {
    instances.put(`/admin/setting-website/${id}`)
        .then(() => {
          dispatch({
            type: UPDATE_WEBSITE
          })
        })
        .then(() => {
          dispatch(getWebsites(getState().websites.params))
        })
  }
}


export const deleteSettingWebsite = (id) => {
  return (dispatch, getState) => {
    instances.delete(`/admin/setting-website/${id}`)
        .then(() => {
          dispatch({
            type: DELETE_WEBSITE
          })
        })
        .then(() => {
          dispatch(getWebsites(getState().websites.params))
        })
  }
}

