export const  PAGE_ENUM = {
  website: 'zili_website',
  landing: 'zili',
  home: 'home',
  promotion: 'promotion',
  banner: 'banner',
  shop_cart: 'shop_cart',

  discount: 'discount',
  flash_sale: 'flash_sale',
  deal_shock: 'deal_shock',
  combo: 'combo',
  voucher: 'voucher',
  outlet: 'outlet',
  sale_off: 'sale_off',

  default_product: 'default_product',
  wardrobe: 'wardrobe',
  info: 'info',
  recruitment: 'recruitment',
  gallery: 'gallery',
  search: 'search',

  desktop: 'desktop',
  mobile: 'mobile',
  none: 'none',
  register: 'register',

  max: 20,
  screen_item: 'screen_item_',
  green_bean: 'green_bean',
  partner: 'partner',
  product: 'product',
  introduce: 'introduce',
  introduce_extension: 'introduce_extension',
  land_extension: 'land_extension',
  showroom: 'showroom',
  store: 'store',
  production: 'production',
  setting_default: 'setting_default',
  setting_mobile: 'setting_mobile',
  setting_desktop: 'setting_desktop',

  home_screen: 'home_screen',
  promotion_screen: 'promotion_screen',
  banner_screen: 'banner_screen',
  recruitment_screen: 'recruitment_screen',

  home_top_banner: 'home_top_banner',
  home_our_services: 'home_our_services',
  home_flash_sale: 'home_flash_sale',
  home_product_best: 'home_product_best',
  home_category: 'home_category',
  home_featured_program: 'home_featured_program',
  home_product_introduce: 'home_product_introduce',
  home_search_best: 'home_search_best',
  home_product_new: 'home_product_new',
  home_promotion_program: 'home_promotion_program',
  home_product_first: 'home_product_first',
  home_product_second: 'home_product_second',
  home_product_three: 'home_product_three',
  home_partner: 'home_partner',
  home_product_more: 'home_product_more',
  home_product_slider: 'home_product_slider',

  promotion_top_banner: 'promotion_top_banner',
  promotion_our_services: 'promotion_our_services',
  promotion_flash_sale_banner: 'promotion_flash_sale_banner',
  promotion_flash_sale: 'promotion_flash_sale',
  promotion_voucher_banner: 'promotion_voucher_banner',
  promotion_voucher: 'promotion_voucher',
  promotion_deal_shock_banner: 'promotion_deal_shock_banner',
  promotion_deal_shock: 'promotion_deal_shock',
  promotion_combo_banner: 'promotion_combo_banner',
  promotion_combo: 'promotion_combo',
  promotion_sale_off_banner: 'promotion_sale_off_banner',
  promotion_sale_off: 'promotion_sale_off',
  promotion_discount_banner: 'promotion_discount_banner',
  promotion_discount: 'promotion_discount',

  banner_gift_top: 'banner_gift_top',
  banner_sell_top: 'banner_sell_top',
  banner_deal_shock_top: 'banner_deal_shock_top',
  banner_combo_top: 'banner_combo_top',
  banner_flash_sale_top: 'banner_flash_sale_top',
  banner_discount_top: 'banner_discount_top',
  banner_sale_off_top: 'banner_sale_off_top',
  banner_voucher_top: 'banner_voucher_top',
  banner_default_product_top: 'banner_default_product_top',
  banner_search_product_top: 'banner_search_product_top',

  recruitment_banner: 'recruitment_banner',
  recruitment_info: 'recruitment_info',
  recruitment_event: 'recruitment_event'

}

