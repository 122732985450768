// ** Initial State
import {
  GET_WEBSITES
} from "../action"

const initialState = {
  websites: []
}

const websites = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEBSITES:
      return {
        ...state,
        websites: action.websites,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}
export default websites
