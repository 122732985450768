// ** Initial State
import {BLOCK_CUSTOMER, EDIT_CUSTOMER, GET_CUSTOMER, GET_CUSTOMERS, GET_ORDERS, GET_CUSTOMER_DETAIL, GET_COINS} from "../action"

const initialState = {
  customers: [],
  orders:[],
  customerDetail: {},
  coins: [],
  total: 0,
  totalCoins: 0,
  totalOrders: 0,
  params: {},
  customer: null,
  blockStatus: false
}

const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.customers,
        total: action.totalPages,
        params: action.params
      }
    case GET_CUSTOMER:
      return { ...state, customer: action.customer }
    case EDIT_CUSTOMER:
      return { ...state, customer: action.customer }
    case BLOCK_CUSTOMER:
      return { ...state, blockStatus: action.blockStatus }
    case GET_ORDERS:
      return { ...state, orders: action.orders, totalOrders: action.totalPages}
    case GET_CUSTOMER_DETAIL:
      return { ...state, customerDetail: action.customerDetail}
    case GET_COINS:
      return {...state, coins: action.coinHistories, totalCoins: action.totalPages}
    default:
      return { ...state }
  }
}
export default customers
