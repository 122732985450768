import instances from "../../../../../@core/plugin/axios"
export const GET_CATEGORIES = 'GET_FEAER_CATEGORIES'
export const GET_PRODUCTS = 'GET_FEAER_PRODUCTS'
export const SYNC_PRODUCTS = 'GET_FEAER_PRODUCTS'
export const GET_ALL_PRODUCTS = 'GET_FEAER_ALL_PRODUCTS'
export const GET_PRODUCT = 'GET_FEAER_PRODUCT'
export const GET_COLOR_STYLE = 'GET_COLOR_STYLE'
export const GET_TAG_STYLE = 'GET_TAG_STYLE'
export const EDIT_PRODUCT = 'EDIT_FEAER_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_FEAER_PRODUCT'
export const UPLOAD_IMAGES = 'UPLOAD_IMAGES'
export const UPLOAD_VIDEOS = 'UPLOAD_VIDEOS'
export const GET_WAREHOUSE_HISTORY = 'GET_WAREHOUSE_HISTORY'
export const GET_PRODUCT_LOCATIONS = 'GET_PRODUCT_LOCATIONS'

export const getCategories = () => {
  return async dispatch => {
    await instances.get('/category/all').then(response => {
      dispatch({
        type: GET_CATEGORIES,
        categories: response?.data?.data
      })
    })
  }
}

// ** Get data on page or row change
export const getProducts = params => {
  const config = {params: params}
  return async dispatch => {
    await instances.get('/admin/product/paging', config).then(response => {
      dispatch({
        type: GET_PRODUCTS,
        products: response?.data?.data?.products,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

export const syncProduct = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/product/sync', config).then(response => {
      dispatch({
        type: SYNC_PRODUCTS,
        products: response?.data?.data?.products,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}

export const getAllProducts = () => {
  return async dispatch => {
    await instances.get('/admin/product/all').then(response => {
      dispatch({
        type: GET_ALL_PRODUCTS,
        products: response?.data?.data?.products
      })
    })
  }
}

// ** Get Product
export const getProduct = id => {
  return async dispatch => {
    await instances
      .get(`/admin/product/${id}`)
      .then(async response => {
        dispatch({type: GET_PRODUCT,
          product: response?.data?.data?.product,
          type_values: response?.data?.data?.type_values,
          default_type_values: response?.data?.data?.default_type_values,
          variants: response?.data?.data?.variants
        })
      })
      .catch(err => console.log(err))
  }
}

export const getColorStyle = type => {
  return async dispatch => {
    await instances
        .get(`/category-style/type/${type}`)
        .then(async response => {
          dispatch({type: GET_COLOR_STYLE,
            color_styles: response?.data?.data
          })
        })
        .catch(err => console.log(err))
  }
}

export const getTagStyle = type => {
  return async dispatch => {
    await instances
        .get(`/category-style/type/${type}`)
        .then(async response => {
          dispatch({type: GET_TAG_STYLE,
            tag_styles: response?.data?.data
          })
        })
        .catch(err => console.log(err))
  }
}

export const getVariantMedia = (productId, variantId) => {
  const params = {productId: productId, variantId: variantId}
  const config = { params: params }
  return instances.get(`/admin/product/variant/media`, config)
}

export const getVariantByProductId = (productId) => {
  return instances.get(`/admin/product/variant/${productId}`)
}

export const uploadImages = async formData => {
  const config = {headers: {'Content-Type': 'multipart/form-data'}}
  if (process.env.REACT_APP_UPLOAD_LOCAL === 'true') {
    return await instances.post('/upload/local/images', formData, config)
  } else {
    return await instances.post('/upload/images', formData, config)
  }
}

export const uploadVideo = formData => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  return instances.post('/upload/video', formData, config)
}

export const uploadBlob = (blob) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  const formData = new FormData()
  formData.append('file', blob)
  return instances.post('/upload/blob', formData, config)
}

// ** Edit product
export const editProduct = async (product) => {
  const formData = new FormData()
  let i = 0
  let isUpload = false
  const image_details = []
  product?.product_image_details.forEach(product_image_detail => {
    if (typeof product_image_detail?.id !== 'number') {
      isUpload = true
      formData.append(`images`, product_image_detail.file.data)
      i++
    } else {
      image_details.push(product_image_detail)
    }
  })
  if (isUpload) {
      const files = await uploadImages(formData)
      product.product_image_details.forEach(product_image_detail => {
        files?.data?.data?.forEach(file => {
          if (product_image_detail?.file?.data?.name === file.originalname) {
            const image_detail = {}
            image_detail.file_path = file.originalname
            image_detail.file_name = file.filename
            image_detail.image_url = file.url
            image_detail.thumb_url = file.thumb_url
            image_detail.mimetype = file.mimetype
            image_detail.product_id = product?.id
            image_details.push(image_detail)
          }
        })
      })
  }
  product.product_image_details = []
  let position = -1
  image_details.forEach(image_detail => {
    position++
    if (image_detail?.position === undefined || image_detail?.position === null) image_detail.position = (position + 1)
    product.product_image_details.push(image_detail)
  })
  if (product.product_images?.length > 0) {
    const formData = new FormData()
    let i = 0
    let isUpload = false
    const image_details = []
    product?.product_images?.forEach(product_image => {
      if (typeof product_image?.id !== 'number') {
        isUpload = true
        formData.append(`images`, product_image.file.data)
        i++
      } else {
        image_details.push(product_image)
      }
    })
    if (isUpload) {
      const files = await uploadImages(formData)
      product?.product_images?.forEach(product_image => {
        files?.data?.data?.forEach(file => {
          if (product_image?.file?.data?.name === file.originalname) {
            const image_detail = {}
            image_detail.file_path = file.originalname
            image_detail.file_name = file.filename
            image_detail.image_url = file.url
            image_detail.thumb_url = file.thumb_url
            image_detail.mimetype = file.mimetype
            image_detail.product_id = product?.id
            image_detail.position = product_image?.position
            image_details.push(image_detail)
          }
        })
      })
    }
    product.product_images = []
    image_details.forEach(image_detail => {
      product.product_images.push(image_detail)
    })
  } else product.product_images = []
  const request = {}
  request.id = product.id
  request.product_image_details = product?.product_image_details
  request.product_media = product?.product_media
  request.product_images = product?.product_images
  request.product_types = product?.product_types
  request.product_description = product?.product_description
  request.product_slug = product?.product_slug
  request.name_display = product?.name_display
  request.category_search = product.category_search
  request.category_name = product.category
  request.status = product.status === 'temp' ? 'active' : product.status
  request.is_gift = product.is_gift
  request.is_best = product.is_best
  request.is_new = product.is_new
  request.colors = product.colors
  request.tags = product.tags
  request.meta_title = product?.meta_title
  request.meta_description = product?.meta_description
  return await instances.put(`/admin/product`, request)
}

export const updateVariantProduct = (productMedia, productVariantMedia, images, productId, color, image_base_url, image_base_url_thumb, image_color_url, image_color_thumb, color_value) => {
  const request = {
    product_media: productMedia,
    product_variant_media: productVariantMedia,
    images: images,
    color: color,
    id: productId,
    image_base_url,
    image_base_url_thumb,
    image_color_url,
    image_color_thumb,
    color_value
  }
  return instances.post(`/admin/product/media`, request)
}

export const updateStatusVariant = (product_id, productVariants) => {
  const request = {product_id: product_id, product_variants: productVariants}
  return instances.post(`/admin/product/variant/status`, request)
}

// ** Delete Product
export const deleteProduct = id => {
  return (dispatch, getState) => {
    instances.delete('/admin/product', { id })
      .then(() => {
        dispatch({
          type: DELETE_PRODUCT
        })
      })
      .then(() => {
        dispatch(getProducts(getState().products.params))
      })
  }
}

export const getWarehouseHistory = (params) => {
  return (dispatch) => {
    instances.get(`/admin/product/warehouse/history?variant_id=${params?.variant_id}&location_ids=${params?.location_ids}&page=${params?.page}&limit=${params?.limit}`)
        .then((res) => {
          dispatch({
            type: GET_WAREHOUSE_HISTORY,
            variantInventories: res?.data?.data
          })
        })
  }
}

export const getProductLocations = () => {
  return (dispatch) => {
    instances.get(`/admin/location/all`)
        .then((res) => {
          dispatch({
            type: GET_PRODUCT_LOCATIONS,
            productLocations: res?.data?.data?.locations
          })
        })
  }
}
