import {GET_COLLECTION, GET_ALL_COLLECTION, INITIAL_COLLECTION} from '../action'

const initialState = {
    initial_collection: null,
    collection: null,
    collections: []
}

const collection = (state = initialState, action) => {
    switch (action.type) {
        case INITIAL_COLLECTION:
            return {
                ...state,
                initial_collection: action.initial_collection
            }
        case GET_COLLECTION:
            return {
                ...state,
                collection: action.collection
            }
        case GET_ALL_COLLECTION:
            return {
                ...state,
                collections: action.collections,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}

export default collection
