// ** Initial State
import {
  GET_LANDING_GROUPS
} from "../action"

const initialState = {
  landings: []
}

const landings = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANDING_GROUPS:
      return {
        ...state,
        landings: action.landings,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}
export default landings
