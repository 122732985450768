import instances from "../../../../@core/plugin/axios"

export const GET_PROMOTIONS_BY_TYPE = 'GET_PROMOTIONS_BY_TYPE'
export const GET_PROMOTIONS_BY_GIFT = 'GET_PROMOTIONS_BY_GIFT'
export const GET_PROMOTION = 'GET_PROMOTION'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'

export const uploadExcelSellItems = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    return await instances.post('/promotion/deal-shock/admin/upload/excel/product', formData, config)
}

export const uploadExcelGiftItems = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    return await instances.post('/promotion/deal-shock/admin/upload/excel/gift', formData, config)
}

//deal shock
export const getDealShocks = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/deal-shock/admin/all', config).then(res => {
            dispatch({
                type: GET_PROMOTIONS_BY_TYPE,
                promotions: res?.data?.data?.deal_shocks,
                totalPages: res?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getDealShockGifts = () => {
    return async dispatch => {
        await instances.get('/promotion/deal-shock/admin/by-gift').then(res => {
            dispatch({
                type: GET_PROMOTIONS_BY_GIFT,
                promotions: res?.data?.data?.deal_shocks
            })
        })
    }
}

export const getDealShock = async id => {
    return await instances.get(`promotion/deal-shock/admin/${id}`)
}

export const createDealShock = async data => {
    return await instances.post('/promotion/deal-shock/admin/create', data)
}

export const updateDealShock = async (id, data) => {
    return await instances.put(`/promotion/deal-shock/admin/update/${id}`, data)
}

export const deleteDealShock = id => {
    return async (dispatch, getState) => {
        await instances.delete(`/promotion/deal-shock/admin/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_PROMOTION
                })
            })
            .then(() => dispatch(getDealShocks(getState().promotion.params)))
    }
}

//combo promotion
export const getPromotionCombos = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/combo/admin/all', config).then(res => {
            dispatch({
                type: GET_PROMOTIONS_BY_TYPE,
                promotions: res?.data?.data?.combos,
                totalPages: res?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getPromotionCombo = async id => {
    return await instances.get(`promotion/combo/admin/${id}`)
}

export const createPromotionCombo = async data => {
    return await instances.post('/promotion/combo/admin/create', data)
}

export const updateCombo = async (id, data) => {
    return await instances.put(`/promotion/combo/admin/update/${id}`, data)
}

export const deleteCombo = id => {
    return async (dispatch, getState) => {
        await instances.delete(`/promotion/combo/admin/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_PROMOTION
                })
            })
            .then(() => dispatch(getPromotionCombos(getState().promotion.params)))
    }
}

//voucher
export const getVouchers = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/voucher/admin/all', config).then(res => {
            dispatch({
                type: GET_PROMOTIONS_BY_TYPE,
                promotions: res?.data?.data?.vouchers,
                totalPages: res?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getVoucher = async id => {
    return await instances.get(`promotion/voucher/admin/${id}`)
}

export const createVoucher = async data => {
    return await instances.post('/promotion/voucher/admin/create', data)
}

export const updateVoucher = async (id, data) => {
    return await instances.put(`/promotion/voucher/admin/update/${id}`, data)
}

export const deleteVoucher = id => {
    return async (dispatch, getState) => {
        await instances.delete(`/promotion/voucher/admin/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_PROMOTION
                })
            })
            .then(() => dispatch(getVouchers(getState().promotion.params)))
    }
}

//discount
export const getDiscounts = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/discount/admin/all', config).then(res => {
            dispatch({
                type: GET_PROMOTIONS_BY_TYPE,
                promotions: res?.data?.data?.discounts,
                totalPages: res?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getDiscount = async id => {
    return await instances.get(`promotion/discount/admin/${id}`)
}

export const createDiscount = async data => {
    return await instances.post('/promotion/discount/admin/create', data)
}

export const updateDiscount = async (id, discount) => {
    return await instances.put(`/promotion/discount/admin/update/${id}`, discount)
}

export const deleteDiscount = id => {
    return async (dispatch, getState) => {
        await instances.delete(`/promotion/discount/admin/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_PROMOTION
                })
            })
            .then(() => dispatch(getDiscounts(getState().promotion.params)))
    }
}

//flash sale
export const getFlashSales = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/flash-sale/admin/all', config).then(res => {
            dispatch({
                type: GET_PROMOTIONS_BY_TYPE,
                promotions: res?.data?.data?.flash_sales,
                totalPages: res?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getFlashSale = async id => {
    return await instances.get(`promotion/flash-sale/admin/${id}`)
}

export const createFlashSale = async data => {
    return await instances.post('/promotion/flash-sale/admin/create', data)
}


export const deleteFlashSale = id => {
    return async (dispatch, getState) => {
        await instances.delete(`/promotion/flash-sale/admin/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_PROMOTION
                })
            })
            .then(() => dispatch(getFlashSales(getState().promotion.params)))
    }
}

export const updateFlashSale = async (id, data) => {
    return await instances.put(`/promotion/flash-sale/admin/update/${id}`, data)
}

export const createPromotionVoucher = async data => {
    return await instances.post('/admin/promotion/voucher/create', data)
}

export const updateStatusFlag = async (id, data) => {
    return await instances.put(`/admin/promotion/update-status-flag/${id}`, data)
}

export const getProductVariantsByProductIds = (productIds) => {
    return instances.get(`/admin/product/variant/list/${productIds}`)
}
// sale off

export const getPromotionSaleOffs = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/sale-off/admin/all', config).then(res => {
            dispatch({
                type: GET_PROMOTIONS_BY_TYPE,
                promotions: res?.data?.data?.sale_offs,
                totalPages: res?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getPromotionSaleOff = async id => {
    return await instances.get(`promotion/sale-off/admin/${id}`)
}

export const createPromotionSaleOff = async data => {
    return await instances.post('/promotion/sale-off/admin/create', data)
}

export const updateSaleOff = async (id, data) => {
    return await instances.put(`/promotion/sale-off/admin/update/${id}`, data)
}

export const deleteSaleOff = id => {
    return async (dispatch, getState) => {
        await instances.delete(`/promotion/sale-off/admin/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_PROMOTION
                })
            })
            .then(() => dispatch(getPromotionSaleOffs(getState().promotion.params)))
    }
}
