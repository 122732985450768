import instances from '../../../../../@core/plugin/axios'
import {getUsers} from "../../../../users/user/store/action"

export const GET_SAPO_LOCATION = 'GET_SAPO_LOCATION'
export const UPDATE_SAPO_LOCATION = 'UPDATE_SAPO_LOCATION'
export const UPDATE_SAPO_CUSTOMER_GROUP = 'UPDATE_SAPO_CUSTOMER_GROUP'
export const UPDATE_SAPO_TENANT = 'UPDATE_SAPO_TENANT'
export const UPDATE_SAPO_SETTING = 'UPDATE_SAPO_SETTING'
export const GET_SAPO_SUPPLIER = 'GET_SAPO_SUPPLIER'
export const GET_SAPO_STAFF = 'GET_SAPO_STAFF'
export const GET_SAPO_PRODUCT = 'GET_SAPO_PRODUCT'
export const GET_SAPO_ORDER = 'GET_SAPO_ORDER'
export const GET_SAPO_ACCOUNTS = 'GET_SAPO_ACCOUNTS'
export const GET_SAPO_ACCOUNT = 'GET_SAPO_ACCOUNT'
export const GET_SAPO_PAYMENT_METHODS = 'GET_SAPO_PAYMENT_METHODS'
export const GET_SAPO_PAYMENT_METHOD = 'GET_SAPO_PAYMENT_METHOD'
export const GET_SAPO_ORDER_SOURCES = 'GET_SAPO_ORDER_SOURCES'
export const GET_SAPO_ORDER_SOURCE = 'GET_SAPO_ORDER_SOURCE'
export const UPDATE_SAPO_ORDER_SOURCE = 'UPDATE_SAPO_ORDER_SOURCE'
export const GET_SAPO_ORDER_SOURCE_GROUPS = 'GET_SAPO_ORDER_SOURCE_GROUPS'
export const GET_SAPO_ORDER_SOURCE_GROUP = 'GET_SAPO_ORDER_SOURCE_GROUP'
export const REMOVE_SAPO_PAYMENT = 'REMOVE_SAPO_PAYMENT'
export const REMOVE_SAPO_SETTING = 'REMOVE_SAPO_SETTING'
export const REMOVE_SAPO_TENANT = 'REMOVE_SAPO_TENANT'
export const GET_SAPO_CUSTOMER_GROUPS = 'GET_SAPO_CUSTOMER_GROUPS'
export const GET_SAPO_SETTINGS = 'GET_SAPO_SETTINGS'
export const GET_SAPO_TENANTS = 'GET_SAPO_TENANTS'
export const UPDATE_SAPO_STAFF_WEB_ACTIVE = 'UPDATE_SAPO_STAFF_WEB_ACTIVE'

export const getSapoLocation = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/location', config).then(response => {
            dispatch({
                type: GET_SAPO_LOCATION,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoSupplier = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/supplier', config).then(response => {
            dispatch({
                type: GET_SAPO_SUPPLIER,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoCustomerGroup = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/customer-group/paging', config).then(response => {
            dispatch({
                type: GET_SAPO_CUSTOMER_GROUPS,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoSetting = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/setting/paging', config).then(response => {
            dispatch({
                type: GET_SAPO_SETTINGS,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoTenant = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/tenant/paging', config).then(response => {
            dispatch({
                type: GET_SAPO_TENANTS,
                data: response?.data?.data
            })
        })
    }
}

export const syncSapoLocation = async () => {
    return await instances.get('/crawler/sapo/location')
}

export const syncSapoSupplier = async () => {
    return await instances.get('/crawler/sapo/supplier')
}

export const syncSapoPaymentMethod = async () => {
    return await instances.get('/crawler/sapo/payment-method')
}

export const syncSapoPurchaseOrder = async (param) => {
    if (param !== undefined && param !== null) {
        return await instances.get(`/crawler/sapo/purchase-order?${param}`)
    } else {
        return await instances.get(`/crawler/sapo/purchase-order`)
    }
}

export const syncSapoOrderSource = async () => {
    return await instances.get('/crawler/sapo/order-source')
}

export const syncSapoStaff = async () => {
    return await instances.get('/crawler/sapo/staff')
}

export const syncSapoCustomerGroup = async () => {
    return await instances.get('/crawler/sapo/customer-group')
}

export const syncSapoSetting = async () => {
    return await instances.get('/crawler/sapo/setting')
}

export const syncSapoTenant = async () => {
    return await instances.get('/crawler/sapo/tenant')
}

export const getSapoStaff = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/staff', config).then(response => {
            dispatch({
                type: GET_SAPO_STAFF,
                data: response?.data?.data
            })
        })
    }
}


export const getSapoProduct = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/product', config).then(response => {
            dispatch({
                type: GET_SAPO_PRODUCT,
                data: response?.data?.data
            })
        })
    }
}


export const syncProduct = async () => {
    return await instances.get('/crawler/sapo/product')
}

export const getStatusSync = async (status) => {
    return await instances.get(`/crawler/sapo/status/${status}`)
}

export const getSapoOrder = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/order', config).then(response => {
            dispatch({
                type: GET_SAPO_ORDER,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoAccounts = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/account/all', config).then(response => {
            dispatch({
                type: GET_SAPO_ACCOUNTS,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoAccount = (id) => {
    return async dispatch => {
        await instances.get(`/admin/sapo/account/${id}`).then(response => {
            dispatch({
                type: GET_SAPO_ACCOUNT,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoPaymentMethods = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/payment-method/paging', config).then(response => {
            dispatch({
                type: GET_SAPO_PAYMENT_METHODS,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoPaymentMethod = (id) => {
    return async dispatch => {
        await instances.get(`/admin/sapo/payment-method/${id}`).then(response => {
            dispatch({
                type: GET_SAPO_PAYMENT_METHOD,
                data: response?.data?.data
            })
        })
    }
}

export const editSettingPaymentMethod = async (data) => {
    return await instances.put(`admin/sapo/payment-method/${data.id}`, data)
}

export const getSapoOrderSources = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/order-source/paging', config).then(response => {
            dispatch({
                type: GET_SAPO_ORDER_SOURCES,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoOrderSource = (id) => {
    return async dispatch => {
        await instances.get(`/admin/sapo/order-source/${id}`).then(response => {
            dispatch({
                type: GET_SAPO_ORDER_SOURCE,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoOrderSourceGroups = () => {
    return async dispatch => {
        await instances.get('/admin/sapo/order-source/groups').then(response => {
            dispatch({
                type: GET_SAPO_ORDER_SOURCE_GROUPS,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoOrderSourceGroupsPaging = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/sapo/order-source/groups/paging', config).then(response => {
            dispatch({
                type: GET_SAPO_ORDER_SOURCE_GROUPS,
                data: response?.data?.data
            })
        })
    }
}

export const getSapoOrderSourceGroup = (id) => {
    return async dispatch => {
        await instances.get(`/admin/sapo/order-source/group/${id}`).then(response => {
            dispatch({
                type: GET_SAPO_ORDER_SOURCE_GROUP,
                data: response?.data?.data
            })
        })
    }
}

export const updateOrderSourceGroup = async (data) => {
    return await instances.put(`/admin/sapo/order-source/group/${data?.id}`, data)
}

export const createOrderSourceGroup = async (data) => {
    return await instances.post(`/admin/sapo/order-source/group`, data)
}

export const editSettingOrderSource = async (data) => {
    return await instances.put(`admin/sapo/order-source/${data.id}`, data)
}

export const syncSapoOrder = async () => {
    return await instances.get('/crawler/sapo/order')
}

export const syncSapoOrderToDB = async () => {
    return await instances.get('/crawler/sapo/order/from-elastic')
}

export const createSettingSapo = async (data) => {
    return await instances.post(`admin/sapo/account`, data)
}

export const editSettingSapo = async (data) => {
    return await instances.put(`admin/sapo/account/${data.id}`, data)
}

export const updateLocation = (location, id) => {
    return (dispatch) => {
        instances.put(`/admin/location/${id}`, location)
            .then(() => {
                dispatch({
                    type: UPDATE_SAPO_LOCATION
                })
            })
            .then(() => {
                dispatch(getSapoLocation({page: 1, perPage: 20}))
            })
    }
}

export const updateSapoCustomerGroup = (customer_group, id) => {
    return (dispatch) => {
        instances.put(`/admin/sapo/customer-group/${id}`, customer_group)
            .then(() => {
                dispatch({
                    type: UPDATE_SAPO_CUSTOMER_GROUP
                })
            })
            .then(() => {
                dispatch(getSapoCustomerGroup({page: 1, perPage: 20}))
            })
    }
}

export const updateSapoTenant = (tenant, id) => {
    return (dispatch) => {
        instances.put(`/admin/sapo/tenant/${id}`, tenant)
            .then(() => {
                dispatch({
                    type: UPDATE_SAPO_TENANT
                })
            })
            .then(() => {
                dispatch(getSapoTenant({page: 1, perPage: 20}))
            })
    }
}

export const updateSapoSetting = (tenant, id) => {
    return (dispatch) => {
        instances.put(`/admin/sapo/setting/${id}`, tenant)
            .then(() => {
                dispatch({
                    type: UPDATE_SAPO_SETTING
                })
            })
            .then(() => {
                dispatch(getSapoSetting({page: 1, perPage: 20}))
            })
    }
}

export const updateOrderSource = (data) => {
    return (dispatch) => {
        instances.put(`admin/sapo/order-source/${data.id}`, data)
            .then(() => {
                dispatch({
                    type: UPDATE_SAPO_ORDER_SOURCE
                })
            })
            .then(() => {
                dispatch(getSapoOrderSources({page: 1, perPage: 20}))
            })
    }
}

export const updateStaffWebActive = (data) => {
    return (dispatch) => {
        instances.put(`admin/sapo/staff/${data.id}`, data)
            .then(() => {
                dispatch({
                    type: UPDATE_SAPO_STAFF_WEB_ACTIVE
                })
            })
            .then(() => {
                dispatch(getSapoStaff({page: 1, perPage: 20}))
            })
    }
}

export const deletePaymentMethod = (id) => {
    return (dispatch) => {
        instances.delete(`/admin/sapo/payment-method/${id}`)
            .then(() => {
                dispatch({
                    type: REMOVE_SAPO_PAYMENT
                })
            })
            .then(() => {
                dispatch(getSapoPaymentMethods({page: 1, perPage: 20}))
            })
    }
}

export const updateUserWebActive = (data, params) => {
    return (dispatch, getState) => {
        instances.put(`admin/sapo/staff/${data.id}`, data)
            .then(() => {
                dispatch({
                    type: UPDATE_SAPO_STAFF_WEB_ACTIVE
                })
            })
            .then(() => {
                dispatch(getUsers(getState().users.params))
            })
    }
}

export const deleteSapoSetting = (id) => {
    return (dispatch) => {
        instances.delete(`/admin/sapo/setting/${id}`)
            .then(() => {
                dispatch({
                    type: REMOVE_SAPO_SETTING
                })
            })
            .then(() => {
                dispatch(getSapoSetting({page: 1, perPage: 20}))
            })
    }
}

export const deleteSapoTenant = (id) => {
    return (dispatch) => {
        instances.delete(`/admin/sapo/tenant/${id}`)
            .then(() => {
                dispatch({
                    type: REMOVE_SAPO_SETTING
                })
            })
            .then(() => {
                dispatch(getSapoTenant({page: 1, perPage: 20}))
            })
    }
}