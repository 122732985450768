// ** Initial State
import {GET_COMMENTS} from "../action"

const initialState = {
  comments: []
}

const comments = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}
export default comments
