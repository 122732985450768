import {GET_DISTRICTS, GET_PROVINCES, GET_SITEMAPS, GET_WARDS, GET_ERROR} from '../action'

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  siteMaps:[],
  total: 1,
  params: {},
  selectedUser: null,
  errorMessage: null,
  provinces: [],
  districts: [],
  wards: []
}

const siteMaps = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_SITE_MAP':
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_SITEMAP':
      return { ...state }
    case 'EDIT_SITEMAP':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    case GET_SITEMAPS:
      return {
        ...state,
        siteMaps: action.siteMaps,
        total: action.totalPages,
        params: action.params
      }
    case GET_PROVINCES:
      return {
        ...state,
        provinces: action.provinces
      }
    case GET_DISTRICTS:
      return {
        ...state,
        districts: action.districts
      }
    case GET_WARDS:
      return {
        ...state,
        wards: action.wards
      }
    case GET_ERROR:
      return {
        errorMessage: action.errorMessage
      }
    default:
      return { ...state }
  }
}
export default siteMaps
