import instances from "../../../../../../@core/plugin/axios"

export const GET_CONTACTS = 'GET_CONTACTS'

export const getContacts = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/customer/contacts', config).then(response => {
      dispatch({
        type: GET_CONTACTS,
        contacts: response?.data?.data?.customers,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}
