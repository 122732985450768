// ** Initial State
import {
  DELETE_PRODUCT,
  EDIT_PRODUCT, GET_ALL_PRODUCTS,
  GET_CATEGORIES, GET_COLOR_STYLE,
  GET_PRODUCT, GET_PRODUCT_LOCATIONS,
  GET_PRODUCTS, GET_TAG_STYLE, GET_WAREHOUSE_HISTORY, SYNC_PRODUCTS,
  UPLOAD_IMAGES,
  UPLOAD_VIDEOS
} from "../action"

const initialState = {
  products: [],
  allProducts: [],
  categories: [],
  total: 0,
  params: {},
  product: null,
  variants: [],
  type_values: [],
  default_type_values: [],
  images: [],
  video: null,
  color_styles: [],
  tag_styles: [],
  variantInventories: [],
  productLocations: []
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      const categoryConverts = []
        categoryConverts.push({value: '', label: 'Nhóm sản phẩm'})
        action?.categories?.forEach(category => {
          categoryConverts.push({value: category.id, label: category.category})
        })
      return {...state, categories: categoryConverts}
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.products,
        total: action.totalPages,
        params: action.params
      }
    case SYNC_PRODUCTS:
      return {
        ...state,
        products: action.products,
        total: action.totalPages,
        params: action.params
      }
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.products
      }
    case GET_PRODUCT:
      return { ...state,
        product: action.product,
        type_values: action.type_values,
        default_type_values: action.default_type_values,
        variants: action.variants
      }
    case EDIT_PRODUCT:
      return { ...state }
    case DELETE_PRODUCT:
      return { ...state }
    case UPLOAD_IMAGES:
      return {
        ...state,
        images: action.images
      }
    case UPLOAD_VIDEOS:
      return {
        ...state,
        video: action.video
      }
    case GET_COLOR_STYLE:
      return {
        ...state,
        color_styles: action.color_styles
      }
    case GET_TAG_STYLE:
      return {
        ...state,
        tag_styles: action.tag_styles
      }
    case GET_WAREHOUSE_HISTORY:
      return {
        ...state,
        variantInventories: action.variantInventories
      }
    case GET_PRODUCT_LOCATIONS:
      return {
        ...state,
        productLocations: action.productLocations
      }
    default:
      return { ...state }
  }
}
export default products
