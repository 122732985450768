// ** Initial State
import {
  GET_CATEGORY,
  GET_PAGING_CATEGORIES
} from "../action"

const initialState = {
  categories: [],
  category: null
}

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGING_CATEGORIES:
      return {...state,
        categories: action.categories,
        total: action.totalPages,
        params: action.params
      }
    case GET_CATEGORY:
      return {...state,
        category: action.category
      }
    default:
      return { ...state }
  }
}
export default categories
