import instances from "../../../../../@core/plugin/axios"

export const GET_ALL_RATINGS = 'GET_ALL_RATINGS'
export const CREATE_REPLY = 'CREATE_REPLY'
export const UPDATE_REPLY = 'UPDATE_REPLY'
export const SHOW_HIDE_REVIEW = 'SHOW_HIDE_REVIEW'
export const SHOW_HIDE_REVIEWS = 'SHOW_HIDE_REVIEWS'
export const DELETE_REVIEW = 'DELETE_REVIEW'
export const DELETE_REVIEWS = 'DELETE_REVIEWS'

// ** Get all Data
export const getAllData = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/review/all', config).then(response => {
            dispatch({
                type: GET_ALL_RATINGS,
                data: response?.data?.data?.reviews,
                totalPage: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

// ** Get paging review
export const pagingReviews = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/review/paging', config).then(response => {
            dispatch({
                type: GET_ALL_RATINGS,
                data: response?.data?.data?.reviews,
                totalPage: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

// ** create data
export const createReply = (...args) => {
    return async (dispatch, getState) => {
        await instances.post('/admin/review/reply', ...args).then(response => {
            dispatch({
                type: CREATE_REPLY
            })
        })
            .then(() => {
                dispatch(pagingReviews(getState().ratings.params))
            })
            .catch(err => console.log(err))
    }
}
// ** update data
export const updateReply = (requestBody, id) => {
    return async (dispatch, getState) => {
        await instances.put(`/admin/review/reply/${id}`, requestBody)
            .then(() => {
                dispatch({
                    type: UPDATE_REPLY
                })
            })
            .then(() => {
                dispatch(pagingReviews(getState().ratings.params))
            })
            .catch(err => console.log(err))
    }
}

export const showHideReview = (id, isActive) => {
    const data = { reviewId: id, isActive: isActive}
    return async (dispatch, getState) => {
        await instances.put(`/review/show_hide`, data)
            .then(() => {
                dispatch({
                    type: SHOW_HIDE_REVIEW
                })
            })
            .then(() => {
                dispatch(pagingReviews(getState().ratings.params))
            })
            .catch(err => console.log(err))
    }
}
export const showHideReviews = (id, isActive) => {
    const data = { reviewId: id, isActive: isActive}
    return async (dispatch, getState) => {
        await instances.post(`/reviews/show_hide`, data)
            .then(() => {
                dispatch({
                    type: SHOW_HIDE_REVIEWS
                })
            })
            .then(() => {
                dispatch(pagingReviews(getState().ratings.params))
            })
            .catch(err => console.log(err))
    }
}

export const deleteReview = id => {
    return async (dispatch, getState) => {
        await instances
            .delete(`/admin/review/delete/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_REVIEW
                })
            })
            .then(() => {
                dispatch(pagingReviews(getState().ratings.params))
            })
            .catch(err => console.log(err))
    }
}
export const deleteReviews = ids => {
    const data = { ids: ids}
    return async (dispatch, getState) => {
        await instances
            .post(`/admin/reviews/delete`, data)
            .then(() => {
                dispatch({
                    type: DELETE_REVIEWS
                })
            })
            .then(() => {
                dispatch(pagingReviews(getState().ratings.params))
            })
            .catch(err => console.log(err))
    }
}