// ** Initial State
import {GET_MIX_PRODUCTS, GET_MIX_PRODUCT, DELETE_MIX_PRODUCT} from "../action"

const initialState = {
    mixProducts: [],
    mixProduct: null,
    params: {},
    total: 0
}

const mixProduct = (state = initialState, action) => {
    switch (action.type) {
        case GET_MIX_PRODUCT:
            return {
                ...state,
                mixProduct: action.mixProduct
            }
        case GET_MIX_PRODUCTS:
            return {
                ...state,
                mixProducts: action.mixProducts,
                total: action.totalPages,
                params: action.params
            }
        case DELETE_MIX_PRODUCT:
            return { ...state }
        default:
            return { ...state }
    }
}
export default mixProduct
