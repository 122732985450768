// ** Get data on page or row change
import instances from "../../../../@core/plugin/axios"
import {GET_CATEGORIES, GET_PRODUCTS} from "../../../../pages/products/product/store/action"
export const GET_SELL_PRODUCTS = 'GET_SELL_PRODUCTS'
export const GET_GIFT_PRODUCTS = 'GET_GIFT_PRODUCTS'
export const GET_FLASH_SALE_PRODUCTS = 'GET_FLASH_SALE_PRODUCTS'
export const GET_COMBO_PRODUCTS = 'GET_COMBO_PRODUCTS'
export const GET_DISCOUNT_PRODUCTS = 'GET_DISCOUNT_PRODUCTS'
export const GET_DEAL_SHOCK_PRODUCTS = 'GET_DEAL_SHOCK_PRODUCTS'
export const GET_SALE_OFF_PRODUCTS = 'GET_SALE_OFF_PRODUCTS'


export const getProducts = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/admin/product/paging', config).then(response => {
            dispatch({
                type: GET_PRODUCTS,
                products: response?.data?.data?.products,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getCategories = () => {
    return async dispatch => {
        await instances.get('/category/all').then(response => {
            dispatch({
                type: GET_CATEGORIES,
                categories: response?.data?.data
            })
        })
    }
}

export const getPromotionProducts = params => {
    params.status = 'active'
    params.sort_name = 'name_display'
    const config = {params: params}
    switch (params.type) {
        case 'DEAL_SHOCK':
            return async dispatch => {
                await instances.get('/promotion/deal-shock/admin/product', config).then(response => {
                    dispatch({
                        type: GET_SELL_PRODUCTS,
                        sellProducts: response?.data?.data?.products,
                        totalPages: response?.data?.data?.totalRecords,
                        params
                    })
                })
            }
        case 'COMBO':
            return async dispatch => {
                await instances.get('/promotion/combo/admin/product', config).then(response => {
                    dispatch({
                        type: GET_SELL_PRODUCTS,
                        sellProducts: response?.data?.data?.products,
                        totalPages: response?.data?.data?.totalRecords,
                        params
                    })
                })
            }
        case 'DISCOUNT':
            return async dispatch => {
                await instances.get('/promotion/discount/admin/product', config).then(response => {
                    dispatch({
                        type: GET_SELL_PRODUCTS,
                        sellProducts: response?.data?.data?.products,
                        totalPages: response?.data?.data?.totalRecords,
                        params
                    })
                })
            }
        default:
            return async dispatch => {
                await instances.get('/admin/product/paging', config).then(response => {
                    dispatch({
                        type: GET_GIFT_PRODUCTS,
                        giftProducts: response?.data?.data?.products,
                        totalPages: response?.data?.data?.totalRecords,
                        params
                    })
                })
            }
    }
}

export const getFlashSaleProducts = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/flash-sale/product/active', config).then(response => {
            dispatch({
                type: GET_FLASH_SALE_PRODUCTS,
                products: response?.data?.data?.products,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getComboProducts = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/combo/product/active', config).then(response => {
            dispatch({
                type: GET_COMBO_PRODUCTS,
                products: response?.data?.data?.products,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getDiscountProducts = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/discount/product/active', config).then(response => {
            dispatch({
                type: GET_DISCOUNT_PRODUCTS,
                products: response?.data?.data?.products,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getDealShockProducts = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/deal-shock/product/active', config).then(response => {
            dispatch({
                type: GET_COMBO_PRODUCTS,
                products: response?.data?.data?.products,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getSaleOffProducts = params => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/promotion/sale-off/product/active', config).then(response => {
            dispatch({
                type: GET_SALE_OFF_PRODUCTS,
                products: response?.data?.data?.products,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}
