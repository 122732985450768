import instances from "../../../../../@core/plugin/axios"
export const GET_MIX_PRODUCTS = 'GET_MIX_PRODUCTS'
export const GET_MIX_PRODUCT = 'GET_MIX_PRODUCT'
export const DELETE_MIX_PRODUCT = 'DELETE_MIX_PRODUCT'

// ** Get All Mix Product
export const getMixProducts = (params) => {
    const config = {params: params}
    return async dispatch => {
        await instances.get('/product-collection/admin/all', config).then(response => {
            dispatch({
                type: GET_MIX_PRODUCTS,
                mixProducts: response?.data?.data?.product_collections,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

// ** Get Mix Product
export const getMixProduct = id => {
    return async dispatch => {
        await instances
            .get(`/product-collection/admin/${id}`)
            .then(async response => {
                dispatch({
                    type: GET_MIX_PRODUCT,
                    mixProduct: response?.data?.data?.product_collection
                })
            })
            .catch(err => console.log(err))
    }
}

// ** Edit mix product
export const createMixProduct = async (data) => {
    return instances.post('/product-collection/admin/create', data)
}

// ** Edit mix product
export const updateMixProduct = async (id, data) => {
    return instances.put(`/product-collection/admin/update/${id}`, data)
}

// ** Delete mix product
export const deleteMixProduct = id => {
    return (dispatch, getState) => {
        instances.delete(`/product-collection/admin/delete/${id}`, {  })
            .then(() => {
                dispatch({
                    type: DELETE_MIX_PRODUCT
                })
            })
            .then(() => {
                dispatch(getMixProducts(getState().mixProducts.params))
            })
    }
}
