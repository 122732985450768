// ** Initial State
import {
    DELETE_GALLERY,
    GET_ALL_GALLERY, GET_GALLERIES,
    GET_GALLERY, UPDATE_STATUS_GALLERY
} from "../action"

const initialState = {
    galleries: [],
    total: 0,
    params: {},
    gallery: {}
}

const gallery = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_GALLERY:
            return {
                ...state,
                galleries: action.galleries,
                total: action.totalPages,
                params: action.params
            }
        case GET_GALLERIES:
            return {
                ...state,
                galleries: action.galleries
            }
        case GET_GALLERY:
            return { ...state, gallery: action.gallery }
        case UPDATE_STATUS_GALLERY:
            return { ...state }
        case DELETE_GALLERY:
            return { ...state }
        default:
            return { ...state }
    }
}
export default gallery
