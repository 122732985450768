import instances from "../../../../../../@core/plugin/axios"
export const GET_COMMENTS = 'GET_COMMENTS'

// ** Get data on page or row change
export const getComments = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/comment/all', config).then(response => {
      dispatch({
        type: GET_COMMENTS,
        comments: response?.data?.data?.comments,
        totalPages: response?.data?.data?.totalRecords,
        params
      })
    })
  }
}
