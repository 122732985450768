import axios from 'axios'
import instances from "../../../../../@core/plugin/axios"

export const GET_SITEMAPS = 'GET_SITEMAPS'
export const GET_PROVINCES = 'GET_PROVINCES'
export const GET_DISTRICTS = 'GET_DISTRICTS'
export const GET_WARDS = 'GET_WARDS'
export const GET_ERROR = 'GET_ERROR'

// ** Get all Data
export const getAllData = () => {
  return async dispatch => {
    await axios.get('/api/users/list/all-data').then(response => {
      dispatch({
        type: 'GET_ALL_DATA',
        data: response.data
      })
    })
  }
}

// ** Get data on page or row change
export const getData = params => {
  return async dispatch => {
    await axios.get('/api/users/list/data', params).then(response => {
      dispatch({
        type: 'GET_DATA',
        data: response.data.users,
        totalPages: response.data.total,
        params
      })
    })
  }
}

export const getSiteMaps = params => {
  const config = { params: params }
  return async dispatch => {
    await instances.get('/admin/site-map/paging', config).then(response => {
      dispatch({
        type: GET_SITEMAPS,
        siteMaps: response?.data?.data?.siteMaps,
        totalPages: response?.data?.data?.totalRecords,
        params: params
      })
    })
  }
}

export const getProvinces = () => {
  return async dispatch => {
    await instances.get('/address/provinces').then(response => {
      dispatch({
        type: GET_PROVINCES,
        provinces: response?.data?.data?.provinces
      })
    })
  }
}

export const getDistricts = (provinceId) => {
  return async dispatch => {
    await instances.get(`/address/districts/${provinceId}`).then(response => {
      dispatch({
        type: GET_DISTRICTS,
        districts: response?.data?.data?.districts
      })
    })
  }
}

export const getWards = (wardId) => {
  return async dispatch => {
    await instances.get(`/address/wards/${wardId}`).then(response => {
      dispatch({
        type: GET_WARDS,
        wards: response?.data?.data?.wards
      })
    })
  }
}

// ** Get User
export const getSiteMap = id => {
  return async dispatch => {
    await instances
      .get(`/admin/site-map/${id}`)
      .then(response => {
        dispatch({
          type: 'GET_SITE_MAP',
          selectedUser: response.data.data
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Add new user
export const addSiteMap = siteMap => {
  return (dispatch, getState) => {
    instances
      .post('/admin/site-map', siteMap)
      .then(response => {
        dispatch({
          type: 'ADD_SITEMAP',
          siteMap
        })
      })
      .then(() => {
        dispatch(getSiteMaps(getState().users.params))
      })
      .catch(err => dispatch(
          {
            type: 'GET_ERROR',
            errorMessage: err?.response?.data?.message
          })
      )
  }
}

export const updateSiteMap = (siteMap, id) => {
  return dispatch => {
    instances
      .put(`/admin/site-map/${id}`, siteMap)
      .then(res => {
        dispatch({
          type: 'EDIT_SITEMAP',
          siteMap
        })
      })
  }
}

// ** Delete user
export const deleteUser = id => {
  return (dispatch, getState) => {
    axios
      .delete('/apps/users/delete', { id })
      .then(response => {
        dispatch({
          type: 'DELETE_USER'
        })
      })
      .then(() => {
        dispatch(getData(getState().users.params))
        dispatch(getAllData())
      })
  }
}
