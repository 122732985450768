import instances from "../../../../../@core/plugin/axios"
export const GET_SOCIAL_STORIES = 'GET_SOCIAL_STORIES'
export const GET_SOCIAL_STORY = 'GET_SOCIAL_STORY'
export const ADD_SOCIAL_STORY = 'ADD_SOCIAL_STORY'
export const EDIT_SOCIAL_STORY = 'EDIT_SOCIAL_STORY'
export const DELETE_SOCIAL_STORY = 'DELETE_SOCIAL_STORY'

// ** Get data on page or row change
export const getSocialStories = params => {
    const config = { params: params }
    return async dispatch => {
        await instances.get('/admin/social-story/all', config).then(response => {
            dispatch({
                type: GET_SOCIAL_STORIES,
                socialStories: response?.data?.data?.socialStories,
                totalPages: response?.data?.data?.totalRecords,
                params: params
            })
        })
    }
}

// **  Get Social Story
export const getSocialStory = id => {
    return async dispatch => {
        await instances
            .get(`/admin/social-story/${id}`)
            .then(response => {
                dispatch({
                    type: GET_SOCIAL_STORY,
                    socialStory: response?.data?.data?.socialStory
                })
            })
            .catch(err => console.log(err))
    }
}

// ** create Social Story
export const createSocialStory = async instagram => {
    return await instances.post(`/admin/social-story`, instagram)
}

export const updateSocialStory = async (instagram, id) => {
    return await instances.put(`/admin/social-story/${id}`, instagram)
}

export const uploadImage = async formData => {
    const config = {headers: {'Content-Type': 'multipart/form-data'}}
    return await instances.post('/upload/image', formData, config)
}

export const uploadVideo = formData => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    return instances.post('/upload/video', formData, config)
}

export const statusSocialStory = (instagram, id) => {
    return (dispatch, getState) => {
        instances.put(`/admin/social-story/${id}`, instagram)
            .then(() => {
                dispatch({
                    type: EDIT_SOCIAL_STORY
                })
            })
            .then(() => {
                dispatch(getSocialStories(getState().instagram.params))
            })
    }
}

export const deleteSocialStory = id => {
    return (dispatch, getState) => {
        instances.delete(`/admin/social-story/${id}`)
            .then(() => {
                dispatch({
                    type: DELETE_SOCIAL_STORY
                })
            })
            .then(() => {
                dispatch(getSocialStories(getState().instagram.params))
            })
    }
}
