import instances from "../../../../@core/plugin/axios"
export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDER_WEBS = 'GET_ORDER_WEBS'
export const GET_ORDER = 'GET_ORDER'
export const DELETE_ORDER = 'DELETE_ORDER'
export const GET_ORDER_RETURNS = 'GET_ORDER_RETURNS'
export const GET_ORDER_TRACKING_BY_ID = 'GET_ORDER_TRACKING_BY_ID'
export const GET_ORDER_RETURN = 'GET_ORDER_RETURN'
export const GET_USER_ASSIGNEE = 'GET_USER_ASSIGNEE'

export const getOrders = params => {
    const config = { params: params }
    return async dispatch => {
        await instances.get('/admin/order/all', config).then(response => {
            dispatch({
                type: GET_ORDERS,
                orders: response?.data?.data?.orders,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getOrderWebs = params => {
    const config = { params: params }
    return async dispatch => {
        await instances.get('/admin/order/all', config).then(response => {
            dispatch({
                type: GET_ORDER_WEBS,
                order_webs: response?.data?.data?.orders,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getOrderReturns = params => {
    const config = { params: params }
    return async dispatch => {
        await instances.get('/admin/order/return/all', config).then(response => {
            dispatch({
                type: GET_ORDER_RETURNS,
                order_returns: response?.data?.data?.order_returns,
                totalPages: response?.data?.data?.totalRecords,
                params
            })
        })
    }
}

export const getOrderReturn = (id) => {
    return async dispatch => {
        await instances
            .get(`/order/customer/return-by-code/${id}`)
            .then(async response => {
                dispatch({
                    type: GET_ORDER_RETURN,
                    order_return: response?.data?.data
                })
            })
            .catch(error => console.log(error))
    }
}

export const getOrder = (id) => {
    return async dispatch => {
        await instances
            .get(`/order/customer/${id}`)
            .then(async response => {
                dispatch({
                    type: GET_ORDER,
                    order: response?.data?.data?.order
                })
            })
            .catch(error => console.log(error))
    }
}

export const getOrderTrackingById = (id) => {
    return async dispatch => {
        await instances.get(`/order/profile/tracking/${id}`).then(async response => {
            dispatch({
                type: GET_ORDER_TRACKING_BY_ID,
                orderTracking: response?.data?.data?.tracking
            })
        }).catch(error => console.log(error))
    }
}

export const deleteOrder = id => {
    return async dispatch => {
        await instances
            .put(`/admin/order/delete`, {id})
            .then(() => {
                dispatch({
                    type: DELETE_ORDER
                })
            })
    }
}

export const getUserByAssigneeId = (assigneeId) => {
    return async dispatch => {
        await instances
            .get(`/admin/user/sapo/${assigneeId}`)
            .then(async response => {
                dispatch({
                    type: GET_USER_ASSIGNEE,
                    user: response?.data?.data
                })
            })
            .catch(error => console.log(error))
    }
}

export const submitSapoOrder = async id => {
    return await instances.put(`/admin/order/submit/sapo/${id}`)
}
