import axios from "axios"
import {useHistory} from "react-router-dom"
let isAlreadyFetchingAccessToken = false

const instances = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3600/api/v1"
})

instances.interceptors.request.use(
    config => {
        // ** Get token from localStorage
        const accessToken = localStorage.getItem('accessToken')

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
    },
    error => Promise.reject(error)
)

// ** Add request/response interceptor
instances.interceptors.response.use(
    response => response,
    async error => {
        try {
            if (error.response) {
                // Request made and server responded
                const {config, response} = error
                const originalRequest = config
                if (response && response.status === 401) {
                    if (!isAlreadyFetchingAccessToken) {
                        isAlreadyFetchingAccessToken = true
                        const response = await instances.post('/auth/user/refresh-token', {refreshToken: localStorage.getItem('refreshToken')})
                        if (response && response.status === 200 && response?.data?.token) {
                            // ** Update accessToken in localStorage
                            localStorage.setItem('accessToken', response?.data?.token)
                            return new Promise(resolve => {
                                originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
                                resolve(instances(originalRequest))
                            })
                        } else {
                            // ** Remove user, accessToken & refreshToken from localStorage
                            localStorage.removeItem('userData')
                            localStorage.removeItem(config.storageTokenKeyName)
                            localStorage.removeItem(config.storageRefreshTokenKeyName)
                            window.location.href = 'login'
                        }
                    } else {
                        return Promise.reject(error)
                    }
                } else {
                    return Promise.reject(error)
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
            }
        } catch (e) {
            console.log(e)
        }
    }
)

export default instances
