import {
  GET_ALL_RATINGS,
  CREATE_REPLY,
  UPDATE_REPLY,
  SHOW_HIDE_REVIEW,
  DELETE_REVIEW,
  SHOW_HIDE_REVIEWS, DELETE_REVIEWS
} from "../action"

// ** Initial State
const initialState = {
  ratings: [],
  total: 0,
  params: {}
}

const ratings = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_RATINGS:
      return { ...state, ratings: action?.data, total: action?.totalPage, params: action?.params }
    case CREATE_REPLY:
      return { ...state}
    case UPDATE_REPLY:
      return { ...state}
    case SHOW_HIDE_REVIEW:
      return { ...state}
    case SHOW_HIDE_REVIEWS:
      return {...state}
    case DELETE_REVIEW:
      return { ...state}
    case DELETE_REVIEWS:
      return { ...state}
    default:
      return { ...state }
  }
}
export default ratings
