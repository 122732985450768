// ** Initial State
import {GET_NOTIFICATIONS, GET_NOTIFICATION, DELETE_NOTIFICATION, GET_ALL_CATEGORY_URL} from "../action"

const initialState = {
    notifications: [],
    notification: null,
    params: {},
    total: 0,
    urls: []
}

const notification = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION:
            return {
                ...state,
                notification: action.notification
            }
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications,
                total: action.totalPages,
                params: action.params
            }
        case DELETE_NOTIFICATION:
            return { ...state }
        case GET_ALL_CATEGORY_URL:
            return {
                ...state,
                urls: action.urls
            }
        default:
            return { ...state }
    }
}
export default notification
