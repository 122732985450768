// ** Logo
import logo from '@src/assets/images/imgs/logo.png'

const SpinnerComponent = () => {
  return (
    <div className='d-flex align-items-center flex-column justify-content-center fallback-spinner vh-100'>
      <img className='fallback-logo' src={logo} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
