import instances from "../../../../../@core/plugin/axios"

export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS'
export const GET_SUPPLIERS = 'GET_SUPPLIERS'

export const getPurchases = async params => {
    const config = {params: params}
    return await instances.get('/admin/purchase/all', config)
}
export const getPurchaseGroup = async (id) => {
    return await instances.get(`/admin/purchase/${id}`)
}
export const getPurchase = async (id) => {
    return await instances.get(`/admin/purchase/${id}`)
}
export const getSuppliers = async params => {
    const config = {params: params}
    return await instances.get('/admin/supplier/all', config)
}
export const getProductVariants = async params => {
    const config = {params: params}
    return await instances.get('/admin/product/variant/all', config)
}
export const getPaymentMethods = async () => {
    return await instances.get('/admin/sapo/payment-method/all')
}
export const getLocation = async () => {
    return await instances.get('/admin/sapo/location/all')
}
export const getSyncSapo = async (id) => {
    return await instances.get(`/admin/purchase/sapo/${id}`)
}
export const createPurchaseGroup = async (data) => {
    return instances.post('/admin/purchase/group', data)
}
export const updatePurchaseGroup = async (id, data) => {
    return await instances.put(`/admin/purchase/group/${id}`, data)
}

export const createPurchase = async (data) => {
    return instances.post('/admin/purchase', data)
}
export const updatePurchase = async (id, data) => {
    return await instances.put(`/admin/purchase/${id}`, data)
}